var suggestedArticles = {
  $container: $('.suggested-articles ul'),
  slickSettings: {
    slide: '.suggested-article',
    // dots: true,
    infinite: true,
    mobileFirst: true,
    slidesToShow: 1,
    speed: 500,
    zIndex: 500,
    responsive: [
      {
        breakpoint: 668,
        settings: 'unslick',
      },
    ],
  },

  init: function () {
    // Initialise Infobox slider
    if ($(window).width() <= 667) {
      suggestedArticles.$container.slick(suggestedArticles.slickSettings);
    }

    $(window).on('resize', function () {
      suggestedArticles.windowResize();
    });
  },

  windowResize: function () {
    if ($(window).width() <= 667) {
      suggestedArticles.$container
        .filter('.slick-initialized')
        .slick('unslick');
      suggestedArticles.$container.slick(suggestedArticles.slickSettings);
    } else {
      suggestedArticles.$container
        .filter('.slick-initialized')
        .find('[tabindex=-1]')
        .attr('tabindex', '');
      suggestedArticles.$container
        .filter('.slick-initialized')
        .slick('unslick');
    }
  },
};

module.exports = {
  init: suggestedArticles.init,
};

module.exports = {
    init: function () {
		$('#category-tag-toggle').click(function () {
			$('#category-tab').addClass('active');
            $('#location-tab').removeClass('active');

            target = $(this).attr('href');
            $('.tab-content > div').not(target).hide();
            
            $(target).fadeIn(1000);
		});
	},
}
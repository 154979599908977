var analytics = require('./analytics.js');

var social = {
  $container: $('.share-links-container'),
  $messenger: $('.facebook-messenger'),
  $envelope: $('.envelope'),
  $whatsApp: $('.whatsapp'),
  themeUrl: $('meta[name=theme_url]').attr('content'),

  init: function () {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // social.addMessenger(social.$container.data('title'), social.$container.data('link'));
      // social.addWhatsApp(social.$container.data('title'), social.$container.data('link'));
    } else {
      social.$whatsApp.addClass('-hidden');
      social.$messenger.addClass('-hidden');
    }

    social.$container.find('a').on('click', function (e) {
      var network = $(this).data('network');
      var position = $(this).parents('.share-links-container').data('position');

      analytics.track('Advocacy', 'share-' + position + '-' + network.toLowerCase(), network, 150, false);

      if (!$(this).hasClass('no-popup')) {
        e.preventDefault();
        window.open($(this).attr('href'), 'popupWindow', 'width=500,height=253,scrollbars=yes');
      }
    });
  },

  init: function() {
    var $temp = $("<input>");
    var $url = $(location).attr('href');

    $('.copy-to-clipboard').on('click', function() {
      $("body").append($temp);
      $temp.val($url).select();
      document.execCommand("copy");
      $temp.remove();
    })
  }
};

module.exports = {
  init: social.init,
};

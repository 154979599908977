var subscribeForm = {

	init: function () {
		// Fill hidden field with same value as other form field
		$('input[name="cm-name"]').on('change', function() {
		    $('input[name="cm-f-dyjllrti"]').val($(this).val());
		});
	},
};

module.exports = {
	init: subscribeForm.init,
};

var videoFigure = {

	init: function () {
		if ($('body').hasClass('bg-mp4-support')) {
			$('figure.-use-video').each(function () {
				$(this).find('video').css({
					objectPosition: 'center ' + $(this).data('gravity'),
				});
				$(this).removeAttr('data-video data-gravity');
			});

			if (document.createElement('detect').style.objectFit !== '') {
				$(window).resize(function () {
					videoFigure.checkAspectRatio();
				});
			}
		}
	},

	checkAspectRatio: function () {

		var width = window.innerWidth;
		var height = window.innerHeight;

		var heroWidth = $('.hero').width();
		var heroHeight = $('.hero').height();

		var videoWidth = $('.hero').find('video').width();
		var videoHeight = $('.hero').find('video').height();

		$('figure.-use-video').each(function () {
			if (heroWidth > videoWidth && heroHeight > videoHeight) {
				$('.hero').find('video').css({
					objectFit: 'cover',
					fontFamily: 'object-fit: cover;',
					objectPosition: '0 0',
					position: 'absolute',
					left: 0,
					height: '130%',
					width: 'auto',
					right: 0,
					bottom: 0,
					margin: 'auto',
					display: 'block',
				});
			} else if (heroWidth > videoWidth) {
				$('.hero').find('video').css({
					objectFit: 'cover',
					fontFamily: 'object-fit: cover;',
					objectPosition: '0 0',
					position: 'absolute',
					left: 0,
					width: '110%',
					height: 'auto',
					right: 0,
					bottom: 0,
					margin: 'auto',
					display: 'block',
				});
			} else if (heroHeight > videoHeight) {
				$('.hero').find('video').css({
					objectFit: 'cover',
					fontFamily: 'object-fit: cover;',
					objectPosition: '0 0',
					position: 'absolute',
					left: 0,
					height: '110%',
					width: 'auto',
					right: 0,
					bottom: 0,
					margin: 'auto',
					display: 'block',
				});
			} else if (width > height) {
				$('.hero').find('video').css({
					objectFit: 'cover',
					fontFamily: 'object-fit: cover;',
					objectPosition: '0 0',
					position: 'absolute',
					left: 0,
					width: '100%',
					height: 'auto',
					right: 0,
					bottom: 0,
					margin: 'auto',
					display: 'block',
				});
			} else {
				$('.hero').find('video').css({
					objectFit: 'cover',
					fontFamily: 'object-fit: cover;',
					objectPosition: '0 0',
					position: 'absolute',
					left: 0,
					height: '100%',
					width: 'auto',
					right: 0,
					bottom: 0,
					margin: 'auto',
					display: 'block',
				});
			}
		});
	},
};

module.exports = {
	init: videoFigure.init,
};

var titleTagline = {
	row: [],
	perRow: 0,
	height: 0,
	small: 668,
	medium: 1025,
	buffer: 14,
	tempArray: [],
	url: window.location.pathname,

	$grid: $('.grid'),
	$tile: $('.grid .tile'),

	init: function () {
		titleTagline.$grid = $('.grid');
		titleTagline.$tile = $('.grid .tile');

		titleTagline.resizeTitleTagline();

		var id;

		$(window).resize(function () {
			window.clearTimeout(id);
			id = window.setTimeout(titleTagline.doneResizing, 5);
		});
	},

	doneResizing: function () {
		titleTagline.$tile.find('.title-tagline').height('auto');
		titleTagline.height = 0;
		titleTagline.loopCounter = 0;
		titleTagline.row = [];
		titleTagline.resizeTitleTagline();
	},

	resizeTitleTagline: function () {
		if ($(window).outerWidth() < titleTagline.small) {
			titleTagline.$tile.find('.title-tagline').height('auto');
		} else {
			if (titleTagline.url.indexOf('category') !== -1) {
				titleTagline.height = 0;

				titleTagline.tempArray = [];
				var $firstRow = titleTagline.$tile.slice(0, 2);
				var $otherTiles = titleTagline.$tile.slice(2, titleTagline.$tile.length);

				$firstRow.each(function () {
					titleTagline.tempArray.push(this);

					if (
						$(this)
							.find('.title-tagline')
							.height() > titleTagline.height
					) {
						titleTagline.height = $(this)
							.find('.title-tagline')
							.height();
					}
				});

				titleTagline.udpateHeight(titleTagline.tempArray);
				titleTagline.tempArray = [];

				$otherTiles.each(function (key) {
					titleTagline.tempArray.push(this);

					if (
						$(this)
							.find('.title-tagline')
							.height() > titleTagline.height
					) {
						titleTagline.height = $(this)
							.find('.title-tagline')
							.height();
					}

					var grid = $(window).width() < titleTagline.medium ? 2 : 3;

					if (key !== 0 && (key + 1) % grid === 0) {
						titleTagline.udpateHeight(titleTagline.tempArray);
						titleTagline.tempArray = [];
					}
				});

				if (titleTagline.tempArray.length) {
					titleTagline.udpateHeight(titleTagline.tempArray);
				}
			} else if (titleTagline.url.indexOf('locations') !== -1 && !$('body').hasClass('archive')) {
				$('.location').each(function () {
					titleTagline.tempArray = [];
					titleTagline.height = 0;

					$(this)
						.find('.grid li')
						.each(function () {
							titleTagline.tempArray.push(this);

							if (
								$(this)
									.find('.title-tagline')
									.height() > titleTagline.height
							) {
								titleTagline.height = $(this)
									.find('.title-tagline')
									.height();
							}
						});

					titleTagline.udpateHeight(titleTagline.tempArray);
				});
			} else if ((titleTagline.url.indexOf('locations') !== -1 && $('body').hasClass('archive')) || titleTagline.url.indexOf('tag') !== -1) {
				titleTagline.tempArray = [];
				titleTagline.height = 0;

				$('.grid .tile').each(function (key) {
					titleTagline.tempArray.push(this);

					if (
						$(this)
							.find('.title-tagline')
							.height() > titleTagline.height
					) {
						titleTagline.height = $(this)
							.find('.title-tagline')
							.height();
					}

					var grid = $(window).width() < titleTagline.medium ? 2 : 3;

					if (key !== 0 && (key + 1) % grid === 0) {
						titleTagline.udpateHeight(titleTagline.tempArray);
						titleTagline.tempArray = [];
					}
				});

				titleTagline.udpateHeight(titleTagline.tempArray);
			}
		}
	},

	udpateHeight: function (array) {
		$(array).each(function () {
			$(this)
				.find('.title-tagline')
				.height(titleTagline.height + 20);
		});

		titleTagline.height = 0;
	},
};

module.exports = {
	init: titleTagline.init,
	doneResizing: titleTagline.doneResizing,
};

var form = {

	$form: $('.feedback form'),

	init: function () {
		form.$form.submit(function (e) {
			e.preventDefault();

			var data = {};

			form.$form.find('.form-row').each(function (key, elem) {
				var $input = $(elem).find('input, textarea');

				if (typeof $input.attr('name') !== typeof undefined &&
					$input.attr('name') !== false) {
					data[$input.attr('name')] = $input.val();
				}
			});

			$.ajax({
				type: form.$form.attr('method'),
				url: form.$form.attr('action'),
				data: data,
				success: function () {
					form.$form.replaceWith($('<p/>').text('Thanks for submitting your feedback.'));
				},

				error: function () {
					form.$form.replaceWith($('<p/>').html('There was a problem processing your feedback. Please try again later or email us at <a href="mailto:parks.blog@environment.nsw.gov.au">parks.blog@environment.nsw.gov.au</a>.'));
				},
			});
		});
	},
};

module.exports = {
	init: form.init,
};

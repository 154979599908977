// GA
var analytics = require('./analytics.js');

var top10Locations = {
	mapStyle: require("./map-styles.js"),
	hashChecked: false,

	init: function() {
		top10Locations.$locations = $(
			".top10locations-locations .-top10locations-location"
		);
		top10Locations.$grid = $(".top10locations-locations .grid");
		top10Locations.$extra = $(".top10locations-extra .extra");
		top10Locations.$hashtag = $(".top10locations-extra .hashtag");
		top10Locations.$tabs = $(".top10locations-leaderboard .tab");
		top10Locations.$tabControls = $(
			".top10locations-leaderboard .tab-control a"
		);
		top10Locations.$themeUrl = $("meta[name=theme_url]").attr("content");
		top10Locations.$backButton = "";
		top10Locations.$locationHashtag = $(".location-hashtag");
		top10Locations.windowWidth = $(window).width();
		top10Locations.markers = {};
		top10Locations.slickSettings = {
			centerMode: true,
			dots: false,
			infinite: true,
			slidesToScroll: 2,
			slidesToShow: 2,
			variableWidth: false,
			adaptiveHeight: true,
			speed: 500,
			zIndex: 500,
			lazyLoad: "ondemand",
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1
					}
				}
			]
		};

		top10Locations.addMap();
		top10Locations.addTileClicks();
		top10Locations.addLeaderboard();
	},

	openHashLocation: function() {
		top10Locations.hashChecked = true;
		if (window.location.hash) {
			$('a[href="' + window.location.hash + '"]').click();
		}
	},

	pinMap: function() {
		$(window).on("scroll", function() {
			var top = $(window).scrollTop();
			var bottom = top + $(window).height();

			if (
				top >= $(".top10locations-locations").offset().top &&
				bottom <= $(".newsletter-2019").offset().top
			) {
				if (!top10Locations.$mapContainer.hasClass("-fixed")) {
					var width = top10Locations.$mapContainer.width();
					var left = top10Locations.$mapContainer.offset().left;
					top10Locations.$mapContainer.addClass("-fixed").css({
						width: width,
						top: 0,
						left: left,
						right: "auto",
						bottom: "auto",
						position: "fixed"
					});
				}
			} else {
				if (top10Locations.$mapContainer.hasClass("-fixed")) {
					top10Locations.$mapContainer.removeClass("-fixed");

					if (top < $(".top10locations-locations").offset().top) {
						top10Locations.$mapContainer.css({
							bottom: "auto",
							right: "auto",
							left: "auto",
							top: "auto",
							position: "relative",
							width: ""
						});
					} else {
						top10Locations.$mapContainer.css({
							bottom: 50,
							right: 0,
							left: "auto",
							top: "auto",
							position: "absolute"
						});
					}
				}
			}
		});
	},

	addLeaderboard: function() {
		top10Locations.$tabControls.click(function() {
			var id = $(this).data("tab");
			var $tab = $("#" + id);
			if (!$tab.hasClass("-active")) {
				top10Locations.$tabs.removeClass("-active");
				top10Locations.$tabControls.removeClass("-active");
				$(this).addClass("-active");
				$tab.addClass("-active");
			}

			return false;
		});
	},

	addTileClicks: function() {
		top10Locations.$locations.find("figure a").click(function() {
			if (top10Locations.$grid.hasClass("slick-initialized")) {
				top10Locations.$grid.slick("unslick");
			}

			top10Locations.showLocation(
				$(this)
					.parents(".-top10locations-location")
					.data("location")
			);
		});
	},

	addMap: function() {
		top10Locations.$mapContainer = $(".top10locations-map");
		top10Locations.$map = $(".top10locations-map .map");
		GoogleMapsLoader.load(function() {
			top10Locations.map = new google.maps.Map(top10Locations.$map[0], {
				center: {
					lat: -33.8688,
					lng: 151.2093
				},
				zoom: 8,
				styles: top10Locations.mapStyle,
				scrollwheel: false
			});

			top10Locations.addLocationPins();

			if (!top10Locations.hashChecked) {
				top10Locations.openHashLocation();
			}

			if (top10Locations.windowWidth > 768) {
				top10Locations.pinMap();
			}
		});

		if (top10Locations.windowWidth <= 667) {
			var $locations = $(".top10locations-locations .grid");
			if (!$locations.hasClass("slick-initialized")) {
				$locations
					.slick(top10Locations.slickSettings)
					.on("afterChange", function(a, b, index) {
						var $slide = $locations.find(
							".-top10locations-location:eq(" + (index + 2) + ")"
						);
						top10Locations.changeMarker($slide.data("location"));
					});
			}
		}
	},

	addLocationPins: function() {
		top10Locations.markerBounds = new google.maps.LatLngBounds();
		top10Locations.$locations.each(function() {
			var locationTitle = $(this)
				.find("h3")
				.text();

			var locationId = $(this).data("location");

			var mapSettings = {};

			if ($(this).hasClass("slick-current")) {
				$(this).addClass("-selected");
				mapSettings = {
					position: {
						lat: $(this).data("latitude"),
						lng: $(this).data("longitude")
					},
					map: top10Locations.map,
					title: locationTitle,
					icon: top10Locations.$themeUrl + "/img/map-pin.png",
					locationId: locationId
				};
			} else {
				mapSettings = {
					position: {
						lat: $(this).data("latitude"),
						lng: $(this).data("longitude")
					},
					map: top10Locations.map,
					title: locationTitle,
					locationId: locationId
				};
			}

			var marker = new google.maps.Marker(mapSettings);

			marker.addListener("click", function() {
				top10Locations.showLocation(this.locationId);
				marker.setIcon(top10Locations.$themeUrl + "/img/map-pin.png");

				return false;
			});

			top10Locations.markers[locationId] = marker;

			top10Locations.markerBounds.extend(marker.getPosition());
		});

		top10Locations.map.fitBounds(top10Locations.markerBounds);
	},

	changeMarker: function(selected) {
		$.each(top10Locations.markers, function() {
			this.setIcon("");
		});

		top10Locations.markers[selected].setIcon(
			top10Locations.$themeUrl + "/img/map-pin.png"
		);
	},

	showLocation: function(id) {
		$("html, body").animate(
			{
				scrollTop: $(".-scroll-top").offset().top - 50
			},
			200
		);

		if (top10Locations.windowWidth <= 768) {
			top10Locations.$backButton = $(".button-container.-mobile");
		} else {
			top10Locations.$backButton = $(".button-container.-desktop .back-button");
		}

		top10Locations.map.setZoom(12);
		top10Locations.map.setCenter(top10Locations.markers[id].getPosition());
		top10Locations.markers[id].setIcon(
			top10Locations.$themeUrl + "/img/map-pin.png"
		);

		top10Locations.$locations.each(function() {
			if (top10Locations.$grid.hasClass("slick-initialized")) {
				top10Locations.$grid.slick("unslick");
			}

			if ($(this).data("location") === id) {
				var locationName = $(this).find('a').attr('href').replace(/#/g, "").replace(/-/g, " ");
				analytics.track('Adventure List Location', 'Click', locationName, 0, false);
				$(this).show();
				$(this).addClass("-active");
			} else {
				$(this).hide();
			}
		});

		top10Locations.$extra.each(function() {
			if ($(this).data("location") === id) {
				$(this).addClass("-active");
				top10Locations.$hashtag.show();
				top10Locations.$backButton.show();
			} else {
				$(this).removeClass("-active");
			}
		});

		top10Locations.$backButton.click(function() {
			var $images = $(
				'.top10locations-extra li[data-location="' +
					id +
					'"] .top10locations-photos'
			);
			top10Locations.map.fitBounds(top10Locations.markerBounds);
			top10Locations.$locations.removeClass("-active").show();
			top10Locations.$extra.removeClass("-active");
			$(this).hide();
			top10Locations.$hashtag.hide();
			$images.slick("unslick");

			if (top10Locations.windowWidth <= 667) {
				if (!top10Locations.$grid.hasClass("slick-initialized")) {
					top10Locations.$grid.slick(top10Locations.slickSettings);
				}
			}

			$("html, body").animate(
				{
					scrollTop: $(".-scroll-top").offset().top - 50
				},
				200
			);

			top10Locations.addMap();
			window.location.hash = "locations";
		});

		var $images = $(
			'.top10locations-extra li[data-location="' +
				id +
				'"] .top10locations-photos'
		);

		if (!$images.hasClass("slick-initialized")) {
			$images.slick(top10Locations.slickSettings);
		}

		// Update locaiton hashtag
		var hashtag = $(
			'.top10locations-locations li[data-location="' + id + '"]'
		).data("hashtag");
		if (hashtag) {
			top10Locations.$locationHashtag.show().html(
				$("<a/>")
					.attr({
						href:
							"https://www.instagram.com/explore/tags/" +
							hashtag.toLowerCase() +
							"/",
						target: "_blank"
					})
					.text("#" + hashtag)
			);
		} else {
			top10Locations.$locationHashtag.hide().html("");
		}

		$(window).resize();
	}
};

module.exports = {
	init: top10Locations.init
};

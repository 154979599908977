module.exports = {
	init: function(){
		$('.article-list-item #infobox-container .get-started').click(function () {
		    $(this).closest('div').toggleClass('-expanded');
		return false;
	});
       $('.article-list-item #infobox-container .toggle-arrow').click(function () {
			$(this).closest('div').toggleClass('-expanded');
        return false;
});
},
};

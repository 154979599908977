var analytics = require('./analytics.js');

var postFigure = {
	$post: $('.article'),
	duration: 400,
	themeUrl: $('meta[name=theme_url]').attr('content'),

	init: function () {
		postFigure.$post.find('figure').each(function () {
			var $lat = $(this).find('.latitude');
			var $lng = $(this).find('.longitude');
			if ($lat.length && $lng.length) {
				$(this).addClass('has-map').attr({
					'data-lat': $lat.text(),
					'data-lng': $lng.text(),
				}).prepend(
					$('<a/>')
						.addClass('toggle-map')
						.html('<span>Show on map</span>')
						.attr('href', '#')
						.click(function () {
							analytics.track('Intent', 'image-map', 'Image map button', 100, false);
							postFigure.showMap(this);
							return false;
						})
				);
				$(this).find('.geolocation a').click(function () {
					analytics.track('Intent', 'image-gps', 'Image GPS map link', 100, false);
					if ($(this).parents('figure').hasClass('map-active')) {
						postFigure.closeMap(this);
					} else {
						postFigure.showMap(this);
					}

					return false;
				});
			}
		});
	},

	showMap: function (elem) {
		var $figure = $(elem).parents('figure');
		var $map = $figure.find('.map');
		var $toggle = $figure.find('.toggle-map');
		var $gmaps = $figure.find('.google-maps');

		$toggle.fadeOut();
		$figure.addClass('map-active');
		if ($map.length) {
			$map.fadeIn();
			$gmaps.fadeIn();
			$figure.find('.close').fadeIn(postFigure.duration);
		} else {
			postFigure.initMap($figure);
		}

		return false;
	},

	initMap: function ($figure) {
		GoogleMapsLoader.load(function () {
			var lat = $figure.data('lat');
			var lng = $figure.data('lng');
			var $map = $('<div/>').addClass('map');

			$figure.find('.image').prepend($map);
			$figure
				.find('.image')
				.append(
					$('<a/>')
						.addClass('close')
						.attr('href', '#')
						.html('<span></span><span></span><span></span>')
						.on('click', function (e) {
							e.preventDefault();

							postFigure.closeMap(this);
							return false;
						})
				)
				.append(
					$('<a/>')
						.addClass('google-maps')
						.attr('href', 'https://maps.google.com.au/maps?q=' + lat + ',' + lng)
						.attr('target', '_blank')
						.html('<span>Open in </span>Google Maps<span class="link-icon fa fa-external-link"</span>')
						.on('click', function (e) {
							if ($(window).width() <= 667) {
								e.preventDefault();

								window.open('comgooglemaps://?center=' + lat + ',' + lng);
							}
						})
				);

			// Init map
			var map = new google.maps.Map($map[0], {
				center: {
					lat: lat,
					lng: lng,
				},
				zoom: 12,
				mapTypeControl: true,
				mapTypeControlOptions: {
					style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: google.maps.ControlPosition.TOP_RIGHT,
				},
				draggable: false,
				scrollwheel: false,
			});

			var marker = {
				url: postFigure.themeUrl + '/img/map-pin.png',
				scaledSize: new google.maps.Size(42, 54),
			};

			map.marker = new google.maps.Marker({
				position: {
					lat: lat,
					lng: lng,
				},
				icon: marker,
				map: map,
			});
		});
	},

	closeMap: function (elem) {
		var $figure = $(elem).parents('figure');
		var $map = $figure.find('.map');
		var $toggle = $figure.find('.toggle-map');
		var $close = $figure.find('.close');
		var $gmaps = $figure.find('.google-maps');

		$figure.removeClass('map-active');
		$map.fadeOut(postFigure.duration);
		$toggle.fadeIn(postFigure.duration);
		$close.fadeOut(postFigure.duration);
		$gmaps.fadeOut(postFigure.duration);
	},
};

module.exports = {
	init: postFigure.init,
};

var videoSupport = {

	init: function () {
		//if (videoSupport.canPlay('video/mp4')) {
		$('body').addClass('bg-mp4-support');
		//}
	},

	isMobile: function () {
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			return true;
		}

		return false;
	},

	canPlay: function (format) {
		var v = document.createElement('video');

		if (v && v.canPlayType && v.canPlayType(format)) {
			return true;
		}
		

		return false;
	},
};

module.exports = {
	init: videoSupport.init,
};

module.exports = {
  init: function () {
    $(".default_option").click(function(){
      $(this).parent().toggleClass("active");
     var currentele = $(this).html();
      if(currentele.includes('location')){
        $('.select_ul #location').closest('li').addClass('hide')
        $('.select_ul #weather').closest('li').removeClass('hide')
        $('.select_ul #keyinfo').closest('li').removeClass('hide')
        $('.select_ul #alerts').closest('li').removeClass('hide')
      }
      if(currentele.includes('weather')){
        $('.select_ul #weather').closest('li').addClass('hide')
        $('.select_ul #location').closest('li').removeClass('hide')
        $('.select_ul #alerts').closest('li').removeClass('hide')
        $('.select_ul #keyinfo').closest('li').removeClass('hide')


      }
      if(currentele.includes('alerts')){
        $('.select_ul #alerts').closest('li').addClass('hide')
        $('.select_ul #keyinfo').closest('li').removeClass('hide')
        $('.select_ul #location').closest('li').removeClass('hide')
        $('.select_ul #weather').closest('li').removeClass('hide')

      }
      if(currentele.includes('keyinfo')){
        $('.select_ul #keyinfo').closest('li').addClass('hide')
        $('.select_ul #location').closest('li').removeClass('hide')
        $('.select_ul #alerts').closest('li').removeClass('hide')
        $('.select_ul #weather').closest('li').removeClass('hide')

      }
    })

    $(".select_ul li").click(function(){
      var currentele = $(this).html();
      $(".default_option li").html(currentele);
      $(this).parents(".select_wrap").removeClass("active");

      if(currentele.includes('weather')){
        $('#weather-card').removeClass('hide')
        $('#keyinfo-card').addClass('hide')
        $('#alerts-card').addClass('hide')
        $('#location-card').addClass('hide')
      }
      if(currentele.includes('alerts')){
        $('#weather-card').addClass('hide')
        $('#keyinfo-card').addClass('hide')
        $('#alerts-card').removeClass('hide')
        $('#location-card').addClass('hide')
      }
      if(currentele.includes('keyinfo')){
        $('#weather-card').addClass('hide')
        $('#keyinfo-card').removeClass('hide')
        $('#alerts-card').addClass('hide')
        $('#location-card').addClass('hide')
      }
      if(currentele.includes('location')){
        $('#weather-card').addClass('hide')
        $('#keyinfo-card').addClass('hide')
        $('#alerts-card').addClass('hide')
        $('#location-card').removeClass('hide')

      }
    })
  },
};

var preventHover = {
	init: function () {
		if ($('p.geolocation').length) {
			$('p.geolocation').mousedown(function (e) {
				e.stopImmediatePropagation();
				e.preventDefault();
			});
		}

		if ($('a.article-title').length) {
			$('a.article-title').mousedown(function (e) {
				e.stopImmediatePropagation();
				e.preventDefault();
			});
		}

		if ($('a.toggle').length) {
			$('a.toggle').mousedown(function (e) {
				e.stopImmediatePropagation();
				e.preventDefault();
			});
		}

		if ($('.loadmore-btn').length) {
			$('.loadmore-btn').mousedown(function (e) {
				e.stopImmediatePropagation();
				e.preventDefault();
			});
		}
	},
};

module.exports = {
	init: preventHover.init,
};

var titleTagline = require('./title-tagline.js');

// GA
var analytics = require('./analytics.js');

var loadMore = {
	$loadMoreContainer: $('.loadmore-container'),
	category: $('.loadmore-container').data('category'),
	section: $('.loadmore-container').data('section'),
	search: $('.loadmore-container').data('search'),
	page: $('.loadmore-container').data('page'),
	base_url: $('meta[name=base_url]').attr('content'),
	perPage: 6,

	init: function () {
		// Show mega-menu when mega-menu link is focused
		loadMore.$loadMoreContainer.on('click', function (e) {
			e.preventDefault();

			if ($('ul.grid').length > 0) {
				// GA
				analytics.track('Engagement', 'load-more', 'Load more', 100, false);

				loadMore.loadNextSection(loadMore.resultsSection);
			} else if (loadMore.section === 'authors') {
				loadMore.loadNextSection(loadMore.resultsAuthor);
			} else {
				loadMore.loadNextSearch();
			}
		});
	},

	loadNextSection: function (success) {
		return $.ajax({
			url: loadMore.base_url + '/section/' + loadMore.section + '/' + loadMore.category + '/' + loadMore.page,
			type: 'GET',
			success: success,
			error: loadMore.outputError,
		});
	},

	loadNextSearch: function () {
		var urlString = loadMore.search;
		urlString = urlString.replace(/ /g, '+');
		return $.ajax({
			url: loadMore.base_url + '/search/' + urlString + '/' + loadMore.page,
			type: 'GET',
			success: loadMore.resultsSearch,
			error: loadMore.outputError,
		});
	},

	resultsSection: function (data) {
		var numPosts = $(data).find('li.-post').length;
		loadMore.page = parseInt(loadMore.page) + 1;
		var render = $(data).find('li.-post').unwrap();
		$('ul.grid').last().append(render);

		if (numPosts < loadMore.perPage) {
			loadMore.hideViewAll();
		}

		titleTagline.init();
		titleTagline.doneResizing();
	},

	resultsAuthor: function (data) {
		var numPosts = $(data).find('li.list-item').length;
		loadMore.page = parseInt(loadMore.page) + 1;

		var render = $(data).find('li.list-item').unwrap();
		$('ul.list').last().append(render);

		if (numPosts < loadMore.perPage) {
			loadMore.hideViewAll();
		}
	},

	resultsSearch: function (data) {
		var numPosts = $(data).find('li.list-item').length;
		loadMore.page = parseInt(loadMore.page) + 1;

		var render = $(data).find('li.list-item').unwrap();
		$('ul.list').last().append(render);

		if (numPosts < loadMore.perPage) {
			loadMore.hideViewAll();
		}
	},

	outputError: function () {
		loadMore.hideViewAll();
	},

	hideViewAll: function () {
		loadMore.$loadMoreContainer.css('display', 'none');
	},
};

module.exports = {
	init: loadMore.init,
};


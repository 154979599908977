module.exports = {
	init: function () {
		$(".select_ul li").click(function(){
            var currentele = $(this).html();
            $(".default_option li").html(currentele);
            $(this).parents(".select_wrap").removeClass("active");
      
            if(currentele.includes('weather')){
              $('.article-list #weather-card').removeClass('hide')
              $('.article-list #keyinfo-card').addClass('hide')
              $('.article-list #alerts-card').addClass('hide')
              $('.article-list #location-card').addClass('hide')
            }
            if(currentele.includes('alerts')){
              $('.article-list #weather-card').addClass('hide')
              $('.article-list #keyinfo-card').addClass('hide')
              $('.article-list #alerts-card').removeClass('hide')
              $('.article-list #location-card').addClass('hide')
            }
            if(currentele.includes('keyinfo')){
              $('.article-list #weather-card').addClass('hide')
              $('.article-list #keyinfo-card').removeClass('hide')
              $('.article-list #alerts-card').addClass('hide')
              $('.article-list #location-card').addClass('hide')
            }
            if(currentele.includes('location')){
              $('.article-list #weather-card').addClass('hide')
              $('.article-list #keyinfo-card').addClass('hide')
              $('.article-list #alerts-card').addClass('hide')
              $('.article-list #location-card').removeClass('hide')
      
            }
          })
	},
};


module.exports = {
	init: function () {
		$('#location-tag-toggle').click(function () {
			$('#location-tab').addClass('active');
            $('#category-tab').removeClass('active');

            target = $(this).attr('href');
            $('.tab-content > div').not(target).hide();
            
            $(target).fadeIn(1000);
		});
	},
};

module.exports = {
    init: function () {
        $('.camera-icon').each(function() {
            $(this).on('mouseenter', function(){
                $(this).parent().next().children().addClass('-active')
                $(this).addClass('-hide')
            }).on('mouseleave', function(){
                $('.caption-wrap').removeClass('-active')
                $(this).removeClass('-hide')
            }).on('touchstart', function(){
                $(this).toggleClass('-active');
            })
        })
       
    },
}

/*jslint browser: true*/

// GA
var analytics = require('./analytics.js');

var eloqua = {
	$input: $('.newsletter form .resizing-input input'),
	$submitA: $('.newsletter form[name=frmYac1] input[type=submit]'),
	$submitB: $('.newsletter form[name=frmYac2] input[type=submit]'),
	firstInteraction: false,

	init: function () {
		eloqua.$input.on('focus', function () {
			if (!eloqua.firstInteraction) {
				eloqua.firstInteraction = true;
				analytics.track('Intent', 'footer-signup-begin', 'Footer newsletter', 80, false);
			}
		});

		eloqua.$submitA.on('click', function () {
			analytics.track('Conversion', 'footer-submit-details', 'Footer newsletter', 150, false);
		});

		eloqua.$submitB.on('click', function () {
			analytics.track('Conversion', 'footer-submit-interests', 'Footer newsletter', 200, false);
		});
	},
};

module.exports = {
	init: eloqua.init,
};

var analytics = require('./analytics.js');

var footer = {
	init: function () {
		$('#primary-footer .logo a').click(function () {
			var action = $(this).data('action');
			analytics.track('Conversion', 'footer-logo-' + action, 'Footer logos', 100, true);
		});

		$('#primary-footer .links a').click(function () {
			analytics.track('Engagement', 'footer-links', 'Footer links', 40, true);
		});
	},
};

module.exports = {
	init: footer.init,
};

/*jshint browser: true*/

var analytics = require('./analytics.js');

var infobox = {
	$elem: $('.infobox'),
	$infobox: $('.infobox .columns'),
	breakpoint: 560,

	init: function () {
		infobox.initEmergencyPlus();

		if (navigator.geolocation) {
			$('.infobox').each(function () {
				$(this).find('.travel').show().find('.locate-me').on('click', function () {
					var $elem = $(this);
					GoogleMapsLoader.load(function () {
						$elem.siblings('.error').hide();
						var $parent = $elem.parent();

						if (!$parent.hasClass('-loading')) {
							analytics.track('Intent', 'infobox-locate-me', 'Infobox - locate me', 100, false);
							$parent.addClass('-loading');
							navigator.geolocation.getCurrentPosition(function (geoposition) {
								infobox.travelEstimate(geoposition, $parent);
							},

							function (error) {
								infobox.handleGeolocationError(error, $parent);
							});
						}

						return false;
					});

					return false;
				});
			});
		} else {
			$(this).find('.travel').hide();
		}

		infobox.addAnalyticsEvents();
	},
  
	initEmergencyPlus: function () {
		// Use platform-specific app link if we're on a mobile OS.
		var $dl = $('.article-infobox .emergency-plus-app .download');

		if ($dl.length) {
			var userAgent = navigator.userAgent || navigator.vendor || window.opera;
			var href;

			// if (/windows phone/i.test(userAgent)) {
			// 	href = 'https://www.windowsphone.com/en-us/store/app/emergency/67badfb5-5cd6-4924-833d-1df4a1214f67';
			// }

			if (/android/i.test(userAgent)) {
				href = 'https://play.google.com/store/apps/details?id=au.gov.nsw.npws.nswparks&hl=en_GB';
			}

			if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
				href = 'https://itunes.apple.com/au/app/nsw-national-parks/id1213696400?mt=8';
			}

			if (href) {
				$dl.attr('href', href);
			}
		}
	},

	travelEstimate: function (geoposition, $elem) {
		var origin = new google.maps.LatLng(geoposition.coords.latitude, geoposition.coords.longitude);
		var destination = new google.maps.LatLng($elem.data('lat'), $elem.data('lng'));

		if (!infobox.distanceMatrixService) {
			infobox.distanceMatrixService = new google.maps.DistanceMatrixService();
		}

		infobox.distanceMatrixService.getDistanceMatrix({
			origins: [origin],
			destinations: [destination],
			travelMode: 'DRIVING',
		}, function (response, status) {
			setTimeout(function () {
				$elem.removeClass('-loading');
			}, 1500);

			if (status === 'OK') {
				// Extract result
				var result = response.rows[0].elements[0];

				// Remove 'locate me button'
				$elem.find('a').remove();

				// Add response
				$elem.append(
					$('<p/>').addClass('travel-time').append(
						$('<div/>').addClass('time').html('<span class="fa fa-clock-o" aria-hidden="true"></span> ' + result.duration.text),
						$('<div/>').addClass('distance').html('<span class="fa fa-car" aria-hidden="true"></span> ' + result.distance.text)
					)
				);
			} else {
				console.log('Directions request failed due to ' + status);
			}
		});
	},

	handleGeolocationError: function (error, $elem) {
		var $locationError = $elem.find('.error');

		setTimeout(function () {
			switch (error.code) {
				case error.PERMISSION_DENIED:
					$locationError.html("Error: Your browser doesn't have location services turned on.");
					$locationError.show();
					break;
				case error.POSITION_UNAVAILABLE:
					$locationError.html('Error: Location information is unavailable.');
					$locationError.show();
					break;
				case error.TIMEOUT:
					$locationError.html('Error: The request to get user location timed out.');
					$locationError.show();
					break;
				case error.UNKNOWN_ERROR:
					$locationError.html('Error: An unknown error occurred.');
					$locationError.show();
					break;
			}

			$locationError.parent().removeClass('-loading');
		}, 1500);
	},

	addAnalyticsEvents: function () {
		$('.infobox .ext-map a').click(function () {
			analytics.track('Intent', 'infobox-maps', 'Infobox - Google maps', 100, false);
		});

		$('.infobox a.-park').click(function () {
			analytics.track('Intent', 'infobox-park-info', 'Infobox - park info', 150, false);
		});

		$('.infobox .park-alerts').click(function () {
			analytics.track('Intent', 'infobox-alerts', 'Infobox - alerts bar', 200, false);
		});

		$('.infobox .download-link').click(function () {
			analytics.track('Intent', 'download-attachment', 'Infobox - download attachment', 0, false);
		});

		$('.article-infobox .emergency-plus-app .download').click(function () {
			analytics.track('Intent', 'infobox-download-app', 'Infobox - download app', 0, false);
		});
	},
};

module.exports = {
	init: infobox.init,
};

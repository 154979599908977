$(document).ready(function () {
  require('./modules/analytics.js').init();
  require('./modules/video-support.js').init();
  require('./modules/menu.js').init();
  require('./modules/footer.js').init();
  require('./modules/video-figure.js').init();
  require('./modules/mobile-infobox.js').init();
  require('./modules/infobox.js').init();
  require('./modules/welcome-category.js').init();
  require('./modules/location-nav.js').init();
  require('./modules/category-nav.js').init();

  // Set Google Maps API key
  GoogleMapsLoader.KEY = $('meta[name=google_api_key]').attr('content');

  if ($('#form-500002').length) {
    require('./modules/eloqua.js');
    require('./modules/eloqua-events.js').init();
  }

  if ($('.article-list').length) {
    require('./modules/article-list-getstarted.js').init();
    require('./modules/article-list-mobile-infobox.js').init();
    require('./modules/init-camera-icon.js').init();
    require('./modules/toggle-image-caption.js').init();
  }

  if ($('#infobox-container').parents('.article-list').length != 1) {
    require('./modules/getstarted.js').init();
  }

  if ($('.page-template-page-survey').length) {
    require('./modules/survey.js');
  }

  // Temp
  if ($('.page-template-page-competition').length) {
    require('./modules/competition.js').init();
  }

  if ($('#newsletter-modal').length) {
    require('./modules/newsletter.js').init();
  }

  if ($('.subscribe-page').length) {
    require('./modules/subscribe-page.js').init();
  }

  // if ($(".hero img").length) {
  // 	objectFitImages($(".hero img"));
  // }

  if ($('.slider').length) {
    require('./modules/slider.js').init();
  }

  if ($('.gallery').length) {
    require('./modules/gallery.js').init();
  }

  if ($('.tax-locations').length) {
    require('./modules/location.js').init();
  }

  if ($('.tax-category').length) {
    require('./modules/category.js').init();
  }

  if ($('body.home').length) {
    require('./modules/welcome.js').init();
  }

  if ($('.title-tagline').length) {
    require('./modules/title-tagline.js').init();
  }

  if ($('html').hasClass('ie9') || $('html').hasClass('lt-ie9')) {
    if ($('.grid').length) {
      require('./modules/grid.js').init();
      require('./modules/placeholder.js').init();
    }
  }

  if ($('.prev-next')) {
    require('./modules/prev-next.js').init();
  }

  if ($('.hero').length) {
    require('./modules/hero-caption.js').init();
  }

  if ($('.share-links-container').length) {
    require('./modules/social.js').init();
  }

  if ($('.article').length) {
    require('./modules/post-figure.js').init();
    require('./modules/post.js').init();
  }

  if ($('.search').length) {
    require('./modules/search.js').init();
  }

  if ($('.notification').length) {
    require('./modules/notification.js').init();
  }

  if (
    $('p.geolocation').length ||
    $('a.article-title').length ||
    $('a.toggle').length ||
    $('.loadmore-btn').length
  ) {
    require('./modules/prevent-hover.js').init();
  }

  if ($('.loadmore-container.-posts').length) {
    require('./modules/load-more.js').init();
  }

  if ($('.loadmore-search-container.-posts').length) {
    require('./modules/load-more-button.js').init();
  }

  if ($('.feedback form').length) {
    require('./modules/feedback-form.js').init();
  }

  if ($('.suggested-articles').length) {
    require('./modules/suggested-articles.js').init();
  }

  if ($('.health-info').length) {
    require('./modules/health-info.js').init();
  }

  if ($('.custom-map').length) {
    require('./modules/custom-map.js').init();
  }

  if ($('.page-template-page-top10locations').length) {
    require('./modules/top10-locations.js').init();
  }

  if ($('.page-template-page-park-stories').length) {
    require('./modules/park-stories.js').init();
  }

  require('./modules/filters.js');

  // Highlight top level nav
  var str = window.location.href;

  if (str.includes('?s')) {
    $('.header-site').toggle();
  }

  if (str === window.location.origin.concat('/#')) {
    $('#filter-button').trigger('click');
  }

  if (str.includes('/locations')) {
    $('.mega-menu-link.-locations-link').addClass('-highlight');
  }

  if (str.includes('/park-stories')) {
    $('.mega-menu-link.-park-stories-link').addClass('-highlight');
  }

  if (str.includes('/category') || str.includes('/categories')) {
    $('.mega-menu-link.-category-link').addClass('-highlight');
  }
});

// Fix Samsung ToString issue
// https://github.com/SamsungInternet/support/issues/56
Function.prototype.ToString = function () {
  return this.toString();
};

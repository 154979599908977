var analytics = require('./analytics.js');

var search = {
  init: function () {
    $('#search-input').one('input', function () {
      analytics.track('Engagement', 'search-field', 'Type search', 20, true);
    });

    $('#search-input').keydown(function (e) {
      if (e.which === 13) {
        $('#filter-submit').trigger('click');
      }
    });

    $('.search').submit(function (event) {
      var value = $('.field').val();
      if (value.length === 0 || value === '') {
        // console.log('search');
        // event.preventDefault();
      } else {
        analytics.track(
          'Engagement',
          'search-submit',
          'Submit search',
          60,
          false
        );
      }
    });
  },
};

module.exports = {
  init: search.init,
};

var parkStories = {
	init: function () {
        $('.share-button button').click(function () {
            $('.form-container').slideToggle();
            $('.share-button .icon').toggleClass('close');

            console.log($('.share-button span').text());

            if ($('.share-button span').text() === 'Share my story') {
                $('.share-button span').text('close');
            } else {
                $('.share-button span').text('Share my story');
            }
        });
	},
};

module.exports = {
	init: parkStories.init,
};

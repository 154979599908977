/*
Code supplied by Jordan Quartermain at Marketing Decisions
jordan@marketingdecisions.com.au
*/

$(document).ready(function () {
	/*jshint validthis: true */
	var $inputs = $('.resizing-input');

	// Resize based on text if text.length > 0
	// Otherwise resize based on the placeholder
	function resizeForText(text) {
		var $this = $(this);
		if (!text.trim()) {
			text = $this.attr('placeholder').trim();
		}

		var $span = $this.parent().find('span');
		$span.text(text);
		var $inputSize = $span.width();
		$this.css('width', $inputSize);
	}

	$inputs.find('input').keypress(function (e) {
		if (e.which && e.charCode) {
			var c = String.fromCharCode(e.keyCode | e.charCode);
			var $this = $(this);
			resizeForText.call($this, $this.val() + c);
		}
	});

	// Backspace event only fires for keyup
	$inputs.find('input').keyup(function (e) {
		if (e.keyCode === 8 || e.keyCode === 46) {
			resizeForText.call($(this), $(this).val());
		}
	});

	$inputs.find('input').each(function () {
		var $this = $(this);
		resizeForText.call($this, $this.val());
	});

	$(function () {
		$.balloon.defaults.css = {
			borderRadius: '5px',
			padding: '3px',
			fontSize: '14px',
			fontWeight: 'bold',
			backgroundColor: 'red',
			zIndex: '2',
			color: 'white',
			boxShadow: '1px 1px 1px #555',
		};
	});
});

//------------------------------------------------------
//---------------- START JS COOKIE FUNCTION ------------
//------------------------------------------------------
(function (factory) {
	var registeredInModuleLoader = false;
	if (typeof define === 'function' && define.amd) { // jshint ignore:line
		define(factory); // jshint ignore:line
		registeredInModuleLoader = true;
	}

	if (typeof exports === 'object') {
		module.exports = factory();
		registeredInModuleLoader = true;
	}

	if (!registeredInModuleLoader) {
		var OldCookies = window.Cookies;
		var api = window.Cookies = factory();
		api.noConflict = function () {
			window.Cookies = OldCookies;
			return api;
		};
	}
}

(function () {
	function extend() {
		var i = 0;
		var result = {};
		for (; i < arguments.length; i++) {
			var attributes = arguments[i];
			for (var key in attributes) {
				result[key] = attributes[key];
			}
		}

		return result;
	}

	function init(converter) {
		function api(key, value, attributes) {
			var result;
			if (typeof document === 'undefined') {
				return;
			}

			// Write

			if (arguments.length > 1) {
				attributes = extend({
					path: '/',
				}, api.defaults, attributes);

				if (typeof attributes.expires === 'number') {
					var expires = new Date();
					expires.setMilliseconds(expires.getMilliseconds() + attributes.expires * 864e+5);
					attributes.expires = expires;
				}

				try {
					result = JSON.stringify(value);
					if (/^[\{\[]/.test(result)) {
						value = result;
					}
				} catch (e) {}

				if (!converter.write) {
					value = encodeURIComponent(String(value))
						.replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent);
				} else {
					value = converter.write(value, key);
				}

				key = encodeURIComponent(String(key));
				key = key.replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent);
				key = key.replace(/[\(\)]/g, escape); // jshint ignore:line

				return (document.cookie = [
					key, '=', value,
					attributes.expires ? '; expires=' + attributes.expires.toUTCString() : '', // use expires attribute, max-age is not supported by IE
					attributes.path ? '; path=' + attributes.path : '',
					attributes.domain ? '; domain=' + attributes.domain : '',
					attributes.secure ? '; secure' : '',
				].join(''));
			}

			// Read

			if (!key) {
				result = {};
			}

			// To prevent the for loop in the first place assign an empty array
			// in case there are no cookies at all. Also prevents odd result when
			// calling "get()"
			var cookies = document.cookie ? document.cookie.split('; ') : [];
			var rdecode = /(%[0-9A-Z]{2})+/g;
			var i = 0;

			for (; i < cookies.length; i++) {
				var parts = cookies[i].split('=');
				var cookie = parts.slice(1).join('=');

				if (cookie.charAt(0) === '"') {
					cookie = cookie.slice(1, -1);
				}

				try {
					var name = parts[0].replace(rdecode, decodeURIComponent);
					cookie = converter.read ?
						converter.read(cookie, name) : converter(cookie, name) ||
						cookie.replace(rdecode, decodeURIComponent);

					/*jshint validthis: true */
					if (this.json) {
						try {
							cookie = JSON.parse(cookie);
						} catch (e) {}
					}

					if (key === name) {
						result = cookie;
						break;
					}

					if (!key) {
						result[name] = cookie;
					}
				} catch (e) {}
			}

			return result;
		}

		api.set = api;
		api.get = function (key) {
			return api.call(api, key);
		};

		api.getJSON = function () {
			return api.apply({
				json: true,
			}, [].slice.call(arguments));
		};

		api.defaults = {};
		api.remove = function (key, attributes) {
			api(key, '', extend(attributes, {
				expires: -1,
			}));
		};

		api.withConverter = init;

		return api;
	}

	return init(function () {});
}));

//----------------------------------------------------//
//---------------- END JS COOKIE FUNCTION ------------//
//----------------------------------------------------//
$(document).ready(function () {

	//-------------------------------------------------------//
	//-----------BEGIN RESIZE OF INPUT FUNCTION--------------//
	//-------------------------------------------------------//
	var $inputs = $('.resizing-input');

	// Resize based on text if text.length > 0
	// Otherwise resize based on the placeholder
	function resizeForText(text) {
		/*jshint validthis: true */
		var $this = $(this);
		if (!text.trim()) {
			text = $this.attr('placeholder').trim();
		}

		var $span = $this.parent().find('span');
		$span.text(text);
		var $inputSize = $span.width();
		$this.css('minWidth', ($inputSize));
	}

	$inputs.find('input').keypress(function (e) {
		if (e.which && e.charCode) {
			var c = String.fromCharCode(e.keyCode | e.charCode);
			var $this = $(this);
			resizeForText.call($this, $this.val() + c);
		}
	});

	// Backspace event only fires for keyup
	$inputs.find('input').keyup(function (e) {
		if (e.keyCode === 8 || e.keyCode === 46) {
			resizeForText.call($(this), $(this).val());
		}
	});

	$inputs.find('input').each(function () {
		var $this = $(this);
		resizeForText.call($this, $this.val());
	});

	//-------------------------------------------------------//
	//-----------------END RESIZE OF INPUT FUNCTION----------//
	//-------------------------------------------------------//

	//------------------------------------------------------//
	//--------BEGIN ANIMATING PLACEHOLDER TEXT--------------//
	//------------------------------------------------------//
	function typeWriter(text, ID) {

		var txt = text;
		var timeOut;
		var txtLen = txt.length;
		var char = 0;
		var fieldID = ID;
		$(fieldID).attr('placeholder', '|');
		(function typeIt() {
			var humanize = Math.round(Math.random() * (250 - 30)) + 30;
			timeOut = setTimeout(function () {
				char++;
				var type = txt.substring(0, char);
				$(fieldID).attr('placeholder', type + '|');
				typeIt();

				if (char === txtLen) {
					$(fieldID).attr('placeholder', $(fieldID).attr('placeholder').slice(0, -1)); // remove the '|'
					clearTimeout(timeOut);
				}

			}, humanize);

			$inputs.find('input').each(function () {
				var $this = $(this);
				resizeForText.call($this, $this.val());
			});
		}());
	}

	$(document).on('scroll', function () {
		var topOfElement = $('.signup').offset().top;
		var bottomOfScreen = $(window).scrollTop() + $(window).height();
		if ((bottomOfScreen > topOfElement)) {
			$(document).off('scroll');
			setTimeout(function () {
				typeWriter('first name', '#firstName');
			}, 500);

			setTimeout(function () {
				typeWriter('email address', '#emailAddress');
			}, 2000);

			setTimeout(function () {
				typeWriter('postcode', '#postCode');
			}, 3950);
		}
	});

	//------------------------------------------------------//
	//--------END ANIMATING PLACEHOLDER TEXT----------------//
	//------------------------------------------------------//

	//set cookie for new visitors by checking if cookie exists
	if (!Cookies.get('form1Complete')) {
		Cookies.set('form2Complete', false, { expires: 2000 });
		Cookies.set('form1Complete', false, { expires: 2000 });
		$('form#form-500002').show();
	}

	//determine what form to display
	if (Cookies.get('form1Complete') === 'false') {
		//display form 1
		$('form#form-500002').show();
	}

	if (((Cookies.get('form1Complete') === 'true') && (Cookies.get('form2Complete') === 'false'))) {
		//display form 2

		$('form#form162').show();

		//prefill email on form 2
		$('#form162 #emailAddressTwo').val(Cookies.get('emailAddress'));
	}

	if (Cookies.get('form2Complete') === 'true') {
		//hide all forms
		$('form#form-500002').hide();
		$('form#form162').hide();
	}

	//-----------------------------------------------------------//
	//---------START process the first form using AJAX-----------//
	//-----------------------------------------------------------//
	$('#form-500002').submit(function (event) {
		var $this = $(this);
		var emailAddress = $('#form-500002 #emailAddress').val();

		//display loading animation
		$('.loader').fadeIn(200);
		$(this).fadeTo(200, 0.5);

		//set some AJAX parameters
		$.ajax({
			type: 'POST',
			url: $('meta[name=base_url]').attr('content') + '/newsletter',
			data: {
				firstName: $('#firstName').val(),
				emailAddress: $('#emailAddress').val(),
				postcode1: $('#postCode').val(),
				pageURL: window.location.href,
				referrerURL: document.referrer,
			},
			encode: true,

			// using the done promise callback (success of data post)
			//'Error:1' = invalid first name
			//'Error:2' = invalid email
			//'Error:3' = invalid postcode
			success: function (data) {
				if (data === 'Error:1') {
					$('#firstName').showBalloon({ contents: 'Please enter your name', offsetY: -3, tipSize: 6 });

					//$("#firstName").addClass("error");
					//$("#firstName").show("input-error");
					//fadeout loading animation
					$('.loader').fadeOut(200);
					$this.fadeTo(200, 1);
					return;
				} else if (data === 'Error:2') {
					$('#firstName').hideBalloon();
					$('#emailAddress').showBalloon({ contents: 'Please enter a valid email', offsetY: -3, tipSize: 6 });

					//$("#emailAddress").addClass("error");
					//$("#emailAddress").addClass("input-error");
					//$("#firstName").removeClass("error");
					//$("#firstName").removeClass("input-error");
					//fadeout loading animation
					$('.loader').fadeOut(200);
					$this.fadeTo(200, 1);
					return;
				} else if (data === 'Error:3') {
					$('#emailAddress').hideBalloon();

					//$("#postCode").addClass("error");
					//$("#postCode").addClass("input-error");
					//$("#emailAddress").removeClass("error");
					//$("#emailAddress").removeClass("input-error");
					$('#postCode').showBalloon({ contents: 'Please enter a postcode', offsetY: -3, tipSize: 6 });

					//fadeout loading animation
					$('.loader').fadeOut(200);
					$this.fadeTo(200, 1);
					return;
				} else {
					$('#postCode').hideBalloon();
					$('#emailAddress').hideBalloon();
					$('#firstName').hideBalloon();

					//fadeout loading animation
					$('.loader').fadeOut(200);
					$this.fadeTo(200, 1);

					//fadeout this form
					$this.hide();

					//fadein new form and thankyou message
					$('#form162').show();
					$('#form162 .anchor:first').focus();
					$('#form162 #emailAddressTwo').val(emailAddress);

					$('html, body').animate({
						scrollTop: ($('#form162 p').offset().top),
					}, 1000);

					$inputs.find('input').each(function () {
						var $this = $(this);
						resizeForText.call($this, $this.val());
					});

					//set cookie to only show form2 for returning visitors
					Cookies.set('form1Complete', true, { expires: 2000 });
					Cookies.set('emailAddress', emailAddress, { expires: 2000 });
				}
			},

			// using the fail promise callback
			error: function () {
				//fadein new form and thankyou messages
				$("<p style='color:red;font-size:30px'>Uh oh! Looks like there was an error!</p>").insertBefore('#form-500002');

				//fadeout loading animation
				$('.loader').fadeOut(200);
				$this.fadeTo(200, 1);
			},
		});

		// stop the form from submitting the normal way and refreshing the page
		event.preventDefault();
	});

	//-----------------------------------------------------------//
	//---------END process the first form using AJAX-------------//
	//-----------------------------------------------------------//

	//-----------------------------------------------------------//
	//---------START process the second form using AJAX----------//
	//-----------------------------------------------------------//
	$('#form162').submit(function (event) {
		var $this = $(this);

		//display loading animation
		$('.loader').fadeIn(200);
		$this.fadeTo(200, 0.5);

		var formData = {
			emailAddress: $('#form162 #emailAddressTwo').val(),
			age: $('#txtAge').val(),
			firstName: $('#firstName').val(),
			pageURL: window.location.href,
			referrerURL: document.referrer,
			interests: [],
			regions: [],
		};

		// Set POST values based on select-ish inputs
		$('#selectInterests input[type=checkbox]:checked:enabled').each(function () {
			formData.interests.push($(this).siblings('label').text());
		});

		$('#selectReg input[type=checkbox]input:checked:enabled').each(function () {
			formData.regions.push($(this).siblings('label').text());
		});

		//set some AJAX parameters
		$.ajax({
			type: 'POST',
			url: $('meta[name=base_url]').attr('content') + '/newsletter',
			data: formData,
			encode: true,

			// using the done promise callback (success of data post)
			//'Error:4' = no details were filled at all (need atleast 1 filled)
			success: function (data) {
				if (data === 'Error:4') {
					$('#form162').showBalloon({
						position: 'bottom',
						contents: 'Please fill out one field',
						offsetY: -5,
						tipSize: 0,
					});

					//$("#txtAge, #regLabel, #interestsLabel").addClass("error");
					//fadeout loading animation
					$('.loader').fadeOut(200);
					$this.fadeTo(200, 1);
					return;
				} else {
					$('#form162').hideBalloon();

					//fadeout loading animation
					$('.loader').fadeOut(200);
					$this.fadeTo(200, 1);

					//fadeout this form
					$this.hide();

					//fadein new form and thankyou message
					$('.thanks-2').show();
					$('html, body').animate({
						scrollTop: ($('.thanks-2').offset().top),
					}, 1000);

					//set cookie to only show form2 for returning visitors
					Cookies.set('form2Complete', true, { expires: 365 });

					//hide first thankyou message
					$('.thanks-1').hide();
				}
			},

			// using the fail promise callback
			error: function () {
				//fadein new form and thankyou message
				$("<p style='color:red;font-size:30px'>Uh oh! Looks like there was an error!</p>").insertBefore('#form162');

				//fadeout loading animation
				$('.loader').fadeOut(200);
				$this.fadeTo(200, 1);
			},
		});

		// stop the form from submitting the normal way and refreshing the page
		event.preventDefault();
	});

	//-----------------------------------------------------------//
	//---------END process the second form using AJAX------------//
	//-----------------------------------------------------------//

	//-----------------------------------------------------------//
	//---------Start form handling with Keyboard-----------------//
	//-----------------------------------------------------------//
	$('.anchor').click(function (event) {
		event.preventDefault();
		return false;
	});

	//escape key to hide popups
	$(document).keyup(function (e) {
		if ((e.keyCode === 27) && $('#selectAge').css('display') === 'block') {
			$('#selectAge').css('display', 'none');
			$.scrollLock(false);
		} else if ((e.keyCode === 27) && $('#selectInterests').css('display') === 'block') {
			$('#selectInterests').css('display', 'none');
			$.scrollLock(false);
		} else if ((e.keyCode === 27) && $('#selectReg').css('display') === 'block') {
			$('#selectReg').css('display', 'none');
			$.scrollLock(false);
		}
	});

	//-----------------------------------------------------------//
	//-----------End form handling with Keyboard-----------------//
	//-----------------------------------------------------------//

	//show the popup for age selection
	$('a#age').click(function () {
		$('#selectInterests, #selectReg').hide();
		$('#selectAge').show();
		$('#form162').hideBalloon();
		$('#rad1').focus();
	});

	//change text based on user's selection
	$('input[type=radio][name=radAge]').change(function () {
		var value = $(this).val();
		$('a#age').text(value);
		$('#txtAge').val(value);
	});

	$('a#interests').click(function () {
		$('#selectAge, #selectReg').hide();
		$('#selectInterests').show();
		$('#form162').hideBalloon();
		$('#intKidsActivities').focus();
	});

	$('a#regions').click(function () {
		$('#selectInterests, #selectAge').hide();
		$('#selectReg').show();
		$('#selectReg #regOutback').focus();
		$('#form162').hideBalloon();
	});

	//count checkboxes selected and append number to label
	function updateCounter(divID, label, text, selected) {
		var len = $(divID + ' input[type=checkbox]:checked').length;
		if (len > 0) {
			$(label).text(len + ' ' + selected);
		} else {
			$(label).text(text);
		}
	}

	$('#selectInterests input:checkbox').on('change', function () {
		updateCounter('#selectInterests', 'a#interests', 'interests', 'items');
	});

	$('#selectReg input:checkbox').on('change', function () {
		updateCounter('#selectReg', 'a#regions', 'regions', 'regions');
	});

	//hide age popup when user selects an age
	$('#selectAge label').click(function () {
		$('#selectAge').hide();
		$.scrollLock(false);
	});

	//hide interests popup if user clicks 'Done'
	$('a#interestsClose').click(function (event) {
		event.preventDefault();
		$('#selectInterests').hide();
		$('a#regions').focus();
		$.scrollLock(false);
	});

	//hide regions popup if user clicks 'Done'
	$('a#regClose').click(function (event) {
		event.preventDefault();
		$('#selectReg').hide();
		$('input[type=submit]').focus();
		$.scrollLock(false);
	});
});

var analytics = require('./analytics.js');

var map = {
	$elem: $('.location-map'),
	$photoInfo: $('.photo-info'),
	mapStyle: require('./map-styles.js'),
	themeUrl: $('meta[name=theme_url]').attr('content'),

	init: function () {
		map.locationId = map.$elem.data('id');
		map.lat = map.$elem.data('lat');
		map.lng = map.$elem.data('lng');
		map.title = map.$elem.data('title');

		// Add map toggle link
		var $toggle = $('<a/>').attr({
			class: 'toggle',
			href: '#',
		}).text('View the ' + map.title + ' map');
		var $geoToggle = $('<div/>').attr({
			class: 'geo-toggle',
		});
		map.$elem.find('.toggle-container').append($toggle);
		map.$photoInfo.find('.geolocation').wrap($geoToggle);
		map.$toggle = $toggle;
		map.$geoToggle = $geoToggle;

		map.$map = map.$elem.find('.location-map-container');

		map.$toggle.click(function () {
			GoogleMapsLoader.load(function () {
				map.extendGoogleMaps();

				map.$elem.toggleClass('-active').one('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function () {
					if (!map.map) {
						map.initMap();
					}
				});

				if ($('html').hasClass('ie9') || $('html').hasClass('lt-ie9')) {
					if (!map.map) {
						map.initMap();
					}
				}

				if (map.$elem.hasClass('-active')) {
					analytics.track('Intent', 'location-map-open', 'Location map', 80, false);
					map.$toggle.text('Close the map');
				} else {
					analytics.track('Engagement', 'location-map-close', 'Location map', 40, false);
					map.$toggle.text('View the ' + map.title + ' map');
				}

				return false;
			});

			return false;
		});
	},

	toggleMap: function () {
		map.$elem.toggleClass('-active').one('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function () {
				if (!map.map) {
					map.initMap();
				}
			});

		if ($('html').hasClass('ie9') || $('html').hasClass('lt-ie9')) {
			if (!map.map) {
				map.initMap();
			}
		}

		if (map.$elem.hasClass('-active')) {
			map.$toggle.text('Close the map');
		} else {
			map.$toggle.text('View the ' + map.title + ' map');
		}

		return false;
	},

	initMap: function () {

		$.ajax({
			url: $('meta[name=base_url]').attr('content') + '/api/locations/' + map.locationId + '/polygon',
			dataType: 'JSON',
			type: 'GET',
			success: function (data) {

				// Init map

				if (map.$elem.data('lat') && map.$elem.data('lng')) {
					map.map = new google.maps.Map(map.$map[0], {
						center: {
							lat: map.lat,
							lng: map.lng,
						},
						zoom: 12,
						styles: map.mapStyle,
						scrollwheel: false,
					});

					var marker = {
						url: map.themeUrl + '/img/map-pin.png',
						scaledSize: new google.maps.Size(42, 54),
					};

					map.marker = new google.maps.Marker({
						position: {
							lat: map.lat,
							lng: map.lng,
						},
						icon: marker,
						map: map.map,
					});
				} else {
					map.map = new google.maps.Map(map.$map[0], {
						zoom: 12,
						styles: map.mapStyle,
						scrollwheel: false,
					});
				}

				// Format Polygon data
				var polygonData = map.formatPolygon(data);
				map.polygon = new google.maps.Polygon({
					paths: polygonData,
					strokeColor: '#007b93',
					strokeOpacity: 0.8,
					strokeWeight: 3,
					fillColor: '#007b93',
					fillOpacity: 0.35,
				});

				map.polygon.setMap(map.map);
				map.map.fitBounds(map.polygon.getBounds());
			},
		});
	},

	formatPolygon: function (data) {
		var polygon = [];
		$.each(data, function (key1, val1) {
			if (val1.length === 2) {
				polygon.push({
					lat: val1[1],
					lng: val1[0],
				});
			} else {
				var _polygon = [];
				$.each(val1, function (key2, val2) {
					_polygon.push({
						lat: val2[1],
						lng: val2[0],
					});
				});

				polygon.push(_polygon);
			}
		});

		return polygon;
	},

	extendGoogleMaps: function () {
		google.maps.Polygon.prototype.getBounds = function () {
			var bounds = new google.maps.LatLngBounds();
			var paths = this.getPaths();
			var path;
			for (var i = 0; i < paths.getLength(); i++) {
				path = paths.getAt(i);
				for (var ii = 0; ii < path.getLength(); ii++) {
					bounds.extend(path.getAt(ii));
				}
			}

			return bounds;
		};
	},
};

var location = {
	init: function () {
		$('.guides .slider a').click(function () {
			analytics.track('Intent', 'guides-click', 'NPWS location guides', 80, false);
		});
	},
};

module.exports = {
	init: function () {
		map.init();
		location.init();
	},
};

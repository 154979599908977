/*jslint browser: true*/
/*global Cookies: true*/

// GA
var analytics = require('./analytics.js');

var modal = {
	cookieSupport: false,
	formProgress: { // Tracks both footer and modal form interaction
		footerFormA: false,
		footerFormB: false,
		modalFormA: false,
		modalFormB: false,
		modalFormDismissed: false,
		firstInteraction: false,
	},
	scrollPos: 0,
	articleProgressTrigger: 50, // Percentage along article body height where modal is triggered
	$lastFocus: '',
	emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	postcodeRegex: /([0-9]{4})/,

	// Debug
	campaignMonitor: false,
	debugOverrides: {
		footerFormA: false,
		footerFormB: false,
		modalFormA: false,
		modalFormB: false,
		modalFormDismissed: false,
	},

	init: function () {
		modal.ebook = $('#newsletter-modal').data('ebook');

		// Check for Cookie support
		modal.cookieSupport = modal.checkCookieSupport();

		if (modal.cookieSupport) {
			// Get Footer Form A completion status
			modal.formProgress.footerFormA = Cookies.get('form1Complete');

			// Get Footer Form B completion status
			modal.formProgress.footerFormB = Cookies.get('form2Complete');

			// Get Modal Form A completion status
			modal.formProgress.modalFormA = Cookies.get('modalForm1Complete');

			// Get Modal Form B completion status
			modal.formProgress.modalFormB = Cookies.get('modalForm2Complete');

			// Check if Modal Form has been dismissed
			modal.formProgress.modalFormDismissed = Cookies.get('modalFormDismissed');

			// Convert formProgress strings to booleans
			for (var key in modal.formProgress) {
				if (modal.formProgress.hasOwnProperty(key)) {
					if (modal.formProgress[key] === 'true') {
						modal.formProgress[key] = true;
					} else if (modal.formProgress[key] === 'false' || modal.formProgress[key] === '' || typeof modal.formProgress[key] === 'undefined') {
						modal.formProgress[key] = false;
					}
				}
			}

			// Initialise modal iff
			// - Footer Form A has not been completed <AND> Modal Form hasn't been dismissed this session, <AND>
			// - User is on an article page, <AND>
			// - Browser is newer than IE8
			if ((!modal.formProgress.footerFormA && !modal.formProgress.modalFormDismissed) && $('body').hasClass('single-post') && !($('html').hasClass('lt-ie9') || $('html').hasClass('ie9'))) {
				modal.scrollWatch();
			}
		} else {
			modal.scrollWatch();
		}
	},

	initModal: function () {
		$.ajax({
			url: $('meta[name=base_url]').attr('content') + '/newsletter/modal/' + modal.ebook,
			type: 'GET',
			dataType: 'html',
			error: function () {
				console.log('error loading modal');
			},

			success: function (html) {
				$('#newsletter-modal').append(html);

				modal.choice = $('#newsletter-modal .newsletter-overlay').data('choice');

				modal.$elem = $('.newsletter-overlay');
				modal.$teaser = modal.$elem.find('.teaser');
				modal.$modal = modal.$elem.find('.modal');
				modal.$progress = modal.$elem.find('.progress');
				modal.$formA = modal.$elem.find('#form-1');
				modal.$formB = modal.$elem.find('#form-2');
				modal.$thanks = modal.$elem.find('.thanks');
				modal.$close = modal.$elem.find('.modal-close');

				modal.$teaser.find('button').click(function () {
					// GA
					analytics.track('Intent', 'modal-open-' + modal.choice, 'Newsletter modal', 0, false);

					modal.$teaser.remove();
					modal.$modal.show();
					modal.disableBodyScroll();
				});

				// Submit Form A
				modal.$formA.find('form').submit(function (e) {
					e.preventDefault();
					var $input = $(this).find('input[type=text],input[type=email]');

					// Check inputs have been filled
					if ($input.length > $input.filter('.-valid').length) {
						$input.not('.-valid').addClass('-error');

						return;
					}

					// GA
					analytics.track('Conversion', 'modal-submit-details-' + modal.choice, 'Newsletter modal', 150, false);

					modal.submitForm($(this));
				});

				// Submit Form B
				modal.$formB.find('form').submit(function (e) {
					e.preventDefault();

					// GA
					analytics.track('Conversion', 'modal-submit-interests-' + modal.choice, 'Newsletter modal', 200, false);

					modal.submitForm($(this));
				});

				// Display filled and validation indicators on user input
				modal.$elem.find('.form-signup input').on('focus', function () {
					var regex;

					if ($(this).data('validation')) {
						regex = $(this).data('validation');

						$(this).on('input propertychange', function () {
							var val = $(this).val();

							if (val.length) {
								$(this).addClass('-filled');

								// GA
								if (!modal.formProgress.firstInteraction) {
									analytics.track('Intent', 'modal-signup-begin-' + modal.choice, 'Newsletter modal', 80, false);

									modal.formProgress.firstInteraction = true;
								}

								if (val.match(modal[regex])) {
									$(this).addClass('-valid');
								} else {
									$(this).removeClass('-valid');
								}
							} else {
								$(this).removeClass('-filled -valid');
							}
						});
					} else {
						$(this).on('input propertychange', function () {
							if ($(this).val()) {
								$(this).addClass('-valid');

								// GA
								if (!modal.formProgress.firstInteraction) {
									analytics.track('Intent', 'modal-signup-begin-' + modal.choice, 'Newsletter modal', 80, false);

									modal.formProgress.firstInteraction = true;
								}
							} else {
								$(this).removeClass('-valid');
							}
						});
					}
				});

				modal.$close.click(function () {
					modal.hideModal();
					modal.enableBodyScroll();
					return false;
				});
			},
		});
	},

	disableBodyScroll: function () {
		var top = $(document).scrollTop();
		$('body,html').addClass('-no-scroll');
		$('body,html').scrollTop(top);
	},

	enableBodyScroll: function () {
		var top = $(document).scrollTop();
		$('body,html').removeClass('-no-scroll');
		$('body,html').scrollTop(top);
	},

	hideModal: function () {
		modal.formProgress.modalFormDismissed = true;

		if (modal.cookieSupport) {
			Cookies.set('modalFormDismissed', true); // Expires at end of session
		}

		// GA
		analytics.track('Intent', 'modal-close-' + modal.choice, 'Newsletter modal', 0, false);
		modal.$elem.remove();
	},

	submitForm: function ($form) {
		var data = {};

		// Build POST data object
		if ($form.hasClass('form-signup')) {
			data = {
				firstName: $form.find('#firstNameModal').val(),
				emailAddress: $form.find('#emailAddressModal').val(),
				postcode1: $form.find('#postcodeModal').val(),
				homeRegion: modal.findRegion($form.find('#postcodeModal').val()),
				pageURL: window.location.href,
				referrerURL: document.referrer,
				ebook: $('#newsletter-modal .newsletter-overlay').data('ebook'),
			};
		} else if ($form.hasClass('form-extra')) {
			var interests = $('#interestsModal').val();
			var regions = $('#regionsModal').val();

			data = {
				emailAddress: Cookies.get('emailAddress'),
				firstName: Cookies.get('firstName'),
				age: $form.find('#ageModal').val(),
				pageURL: window.location.href,
				referrerURL: document.referrer,
				interests: [],
				regions: [],
			};

			// Set POST values based on <select> inputs
			if (interests) {
				var interest = '';
				for (var i = 0; i < interests.length; i++) {
					interest = $('#interestsModal option[value="' + interests[i] + '"]').html();
					interest = interest.replace('&amp;', 'and');
					data.interests.push(interest);
				}
			}

			if (regions) {
				var region = '';
				for (var j = 0; j < regions.length; j++) {
					region = $('#regionsModal option[value="' + regions[j] + '"]').html();
					data.regions.push(region);
				}
			}
		}

		$.ajax({
			type: 'POST',
			url: $('meta[name=base_url]').attr('content') + '/newsletter',
			data: data,
			encode: true,

			beforeSend: function () {
				// Disable submit button
				$form.find('input[type=submit]').attr('disabled', 'disabled').animate({
					opacity: 0.5,
				}, 150);
			},

			// - Errors - //
			// 1: invalid first name
			// 2: invalid email
			// 3: invalid postcode
			// 4: no options selected
			success: function (response) {
				modal.success(response, $form, data);
			},

			error: function (jqXHR, textStatus, errorThrown) {
				alert('We were unable to subscribe you at this time. Please try again later.');
				console.log(jqXHR);
				console.log(textStatus);
				console.log(errorThrown);
			},
		});
	},

	success: function (response, $form, data) {
		// Error handling
		if (response === 'Error:1') {
			$('#firstName').addClass('-error');
			$form.find('input[type=submit]').removeAttr('disabled');

			return;
		} else if (response === 'Error:2') {
			$('#emailAddress2').addClass('-error');
			$form.find('input[type=submit]').removeAttr('disabled');

			return;
		} else if (response === 'Error:3') {
			$('#postcode1').addClass('-error');
			$form.find('input[type=submit]').removeAttr('disabled');

			return;
		} else if (response === 'Error:4') {
			$form.find('input[type=submit]').removeAttr('disabled');

			return;
		} else {
			var expiry = {
				expires: 2000,
			};

			if ($form.hasClass('form-signup')) {
				// Set cookie to only show form2 for returning visitors
				Cookies.set('form1Complete', true, expiry);
				Cookies.set('modalForm1Complete', true, expiry);
				Cookies.set('emailAddress', data.emailAddress, expiry);
				Cookies.set('firstName', data.firstName, expiry);
				modal.formProgress.modalFormA = true;

				modal.$formA.hide();
				modal.$formB.show();

				modal.$formB.find('#emailAddress3').val(data.emailAddress);

				var title = modal.$formB.data('title');
				modal.$elem.find('.modal-title').text(title);

				// Update form progress bar
				modal.updatePercentage(90);

				// Build <select> dropdowns as required
				$('select').each(function () {
					$(this).SumoSelect({});
				});

				return;
			} else if ($form.hasClass('form-extra')) {
				Cookies.set('modalForm2Complete', true, expiry);
				modal.formProgress.modalFormB = true;

				modal.formComplete();

				return;
			}
		}
	},

	updatePercentage: function (percentage) {
		modal.$progress.find('.progress-percentage span').text(percentage);
		modal.$progress.find('.progress-bar div').width(percentage + '%');
	},

	formComplete: function () {
		modal.$formB.hide();
		modal.showThanks();

		modal.formProgress.modalFormDismissed = true;

		if (modal.cookieSupport) {
			Cookies.set('modalFormDismissed', true); // Expires at end of session
		}

	},

	showThanks: function () {
		modal.enableBodyScroll();
		modal.$formB.hide();
		modal.$formA.hide();
		modal.$modal.find('.modal-title').text(modal.$thanks.data('title'));
		modal.updatePercentage(100);
		modal.$thanks.show();
	},

	scrollWatch: function () {

		var loop = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame || window.oRequestAnimationFrame || function (callback) { setTimeout(callback, 1000 / 60); };

		var tick = false;
		var lastScrollPos = 0;

		$(window).on('scroll', onScroll);

		function onScroll() {
			lastScrollPos = $(window).scrollTop();
			requestTick();
		}

		function requestTick() {
			if (!tick) {
				loop(update);
				tick = true;
			}
		}

		function update() {
			var $article = $('article.article');
			var modalTrigger = $article.offset().top + ((modal.articleProgressTrigger / 100) * $article.height());

			if (lastScrollPos + $(window).height() > modalTrigger) {
				$(window).off('scroll');

				modal.initModal();
			}

			tick = false;
		}
	},

	checkCookieSupport: function () {
		var testKey = 'test';

		try {
			Cookies.set(testKey, '1');
			Cookies.remove(testKey);
			return true;
		}

		catch (error) {
			return false;
		}
	},

	findRegion: function (postcode) {
		postcode = parseInt(postcode);
		var region;

		var countryNSW = [2328, 2329, 2333, 2336, 2337, 2338, 2339, 2340, 2341, 2342, 2343, 2344, 2345, 2346, 2347, 2350, 2352, 2353, 2354, 2355, 2356, 2357, 2358, 2359, 2360, 2361, 2365, 2369, 2370, 2371, 2372, 2379, 2380, 2381, 2382, 2388, 2390, 2395, 2396, 2397, 2398, 2399, 2400, 2401, 2402, 2403, 2404, 2405, 2406, 2408, 2409, 2410, 2411, 2475, 2476, 2575, 2576, 2577, 2578, 2579, 2580, 2581, 2582, 2583, 2584, 2585, 2586, 2587, 2594, 2618, 2619, 2620, 2621, 2622, 2623, 2668, 2669, 2671, 2672, 2721, 2726, 2791, 2792, 2793, 2794, 2795, 2797, 2798, 2799, 2800, 2803, 2804, 2805, 2806, 2807, 2808, 2809, 2810, 2817, 2818, 2820, 2821, 2822, 2823, 2824, 2827, 2828, 2829, 2830, 2842, 2843, 2844, 2848, 2849, 2850, 2852, 2864, 2865, 2866, 2867, 2868, 2869, 2870, 2871, 2873, 2874, 2875, 2876, 4375, 4377, 4380, 4383, 4385];
		var murrayRiverina = [2588, 2590, 2640, 2641, 2642, 2643, 2644, 2645, 2646, 2647, 2648, 2650, 2651, 2652, 2655, 2656, 2658, 2659, 2660, 2661, 2663, 2665, 2666, 2675, 2678, 2680, 2681, 2700, 2701, 2702, 2703, 2705, 2706, 2707, 2710, 2711, 2712, 2713, 2714, 2715, 2716, 2717, 2722, 2725, 2727, 2729, 2731, 2732, 2733, 2734, 2735, 2736, 2737, 2738, 2739, 3644, 3691];
		var northCoast = [2264, 2265, 2267, 2278, 2280, 2281, 2282, 2283, 2284, 2285, 2286, 2287, 2289, 2290, 2291, 2292, 2293, 2294, 2295, 2296, 2297, 2298, 2299, 2300, 2302, 2303, 2304, 2305, 2306, 2307, 2308, 2311, 2312, 2315, 2316, 2317, 2318, 2319, 2320, 2321, 2322, 2323, 2324, 2325, 2326, 2327, 2330, 2331, 2334, 2335, 2415, 2420, 2421, 2422, 2423, 2424, 2425, 2426, 2427, 2428, 2429, 2430, 2431, 2439, 2440, 2441, 2443, 2444, 2445, 2446, 2447, 2448, 2449, 2450, 2452, 2453, 2454, 2455, 2456, 2460, 2462, 2463, 2464, 2465, 2466, 2469, 2470, 2471, 2472, 2473, 2474, 2477, 2478, 2479, 2480, 2481, 2482, 2483, 2484, 2485, 2486, 2487, 2488, 2489, 2490];
		var outback = [2386, 2387, 2825, 2826, 2831, 2832, 2833, 2834, 2835, 2836, 2838, 2839, 2840, 2877, 2878, 2879, 2880];
		var snowyMountains = [2611, 2626, 2627, 2628, 2629, 2630, 2631, 2632, 2633, 2642, 2649, 2653, 2720, 2729, 2730, 3707];
		var southCoast = [2500, 2502, 2505, 2506, 2508, 2515, 2516, 2517, 2518, 2519, 2525, 2526, 2527, 2528, 2529, 2530, 2533, 2534, 2535, 2536, 2537, 2538, 2539, 2540, 2541, 2545, 2546, 2548, 2549, 2550, 2551, 2560, 2574, 2631];
		var sydneySurrounds = [2000, 2007, 2008, 2009, 2010, 2011, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2060, 2061, 2062, 2063, 2064, 2065, 2066, 2067, 2068, 2069, 2070, 2071, 2072, 2073, 2074, 2075, 2076, 2077, 2079, 2080, 2081, 2082, 2083, 2084, 2085, 2086, 2087, 2088, 2089, 2090, 2092, 2093, 2094, 2095, 2096, 2097, 2099, 2100, 2101, 2102, 2103, 2104, 2105, 2106, 2107, 2108, 2110, 2111, 2112, 2113, 2114, 2115, 2116, 2117, 2118, 2119, 2120, 2121, 2122, 2125, 2126, 2127, 2128, 2130, 2131, 2132, 2133, 2134, 2135, 2136, 2137, 2138, 2140, 2141, 2142, 2143, 2144, 2145, 2146, 2147, 2148, 2150, 2151, 2152, 2153, 2154, 2155, 2156, 2157, 2158, 2159, 2160, 2161, 2162, 2163, 2164, 2165, 2166, 2167, 2168, 2170, 2171, 2172, 2173, 2174, 2175, 2176, 2177, 2178, 2179, 2190, 2191, 2192, 2193, 2194, 2195, 2196, 2197, 2198, 2199, 2200, 2203, 2204, 2205, 2206, 2207, 2208, 2209, 2210, 2211, 2212, 2213, 2214, 2216, 2217, 2218, 2219, 2220, 2221, 2222, 2223, 2224, 2225, 2226, 2227, 2228, 2229, 2230, 2231, 2232, 2233, 2234, 2250, 2251, 2256, 2257, 2258, 2259, 2260, 2261, 2262, 2263, 2555, 2556, 2557, 2558, 2559, 2563, 2564, 2565, 2566, 2567, 2568, 2569, 2570, 2571, 2572, 2573, 2745, 2747, 2748, 2749, 2750, 2752, 2753, 2754, 2756, 2757, 2758, 2759, 2760, 2761, 2762, 2763, 2765, 2766, 2767, 2768, 2769, 2770, 2773, 2774, 2775, 2776, 2777, 2778, 2779, 2780, 2782, 2783, 2784, 2785, 2786, 2787, 2790, 2845, 2846, 2847];

		if (countryNSW.indexOf(postcode) > -1) {
			region = 'Country NSW';
		} else if (murrayRiverina.indexOf(postcode) > -1) {
			region = 'Murray Riverina';
		} else if (northCoast.indexOf(postcode) > -1) {
			region = 'North Coast';
		} else if (outback.indexOf(postcode) > -1) {
			region = 'Outback';
		} else if (snowyMountains.indexOf(postcode) > -1) {
			region = 'Snowy Mountains';
		} else if (southCoast.indexOf(postcode) > -1) {
			region = 'South Coast';
		} else if (sydneySurrounds.indexOf(postcode) > -1) {
			region = 'Sydney and Surrounds';
		}

		return region;
	},
};

module.exports = {
	init: modal.init,
};

var analytics = require('./analytics.js');

var welcome = {
	init: function () {
		$('.slider.-feature .tile a').click(function () {
			analytics.track('Engagement', 'featured-article', 'Featured module', 50, false);
		});
	},
};

module.exports = {
	init: welcome.init,
};

var base = {
	height: 0,
	small: 667,
	$tiles: $('.prev-next').find('.tile'),

	init: function () {
		base.resize();

		$(window).resize(base.resize);
	},

	resize: function () {
		if ($(window).outerWidth() < base.small) {
			base.$tiles.find('.title').height('auto');
		} else {

			base.height = 0;
			base.$tiles.each(function () {
				var height = $(this).find('.title').height();
				$(this).find('.title').height('auto');
				if ($(this).find('.title').height() > base.height) {
					base.height = $(this).find('.title').height();
				}

				$(this).find('.title').height(height);
			});

			base.setHeight();
		}
	},

	setHeight: function () {
		base.$tiles.find('.title').height(base.height);
	},
};

module.exports = {
	init: base.init,
};

module.exports ={
	init: function () {
        $('.article').find('figure').children('.image').each(function () {
            $(this).prepend(
                $('<span/>')
                    .addClass('camera-icon')
            );
          });
	},
   
};


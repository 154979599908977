$('#filter-button').click(function (e) {
  e.preventDefault();

  $('#filters').toggle();
  if (!window.location.href.includes('?s')) {
    $('.header-site').toggle();
  }
  if (window.matchMedia('(max-width: 667px)').matches) {
    $('#search-image').toggle();
    $('#cross-image').toggle();
  }
});

$('#clear-all').click(function (e) {
  e.preventDefault();
  $('#search-input').prop('value', '');
  $('input[type="checkbox"]:checked').prop('checked', false);
});

$('#cancel').click(function (e) {
  e.preventDefault();
  $('#search-input').prop('value', '');
  $('input[type="checkbox"]:checked').prop('checked', false);
  $('#filter-button').trigger('click');
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
});

$('#clear-all-results').click(function (e) {
  e.preventDefault();
  $('#results-term').prop('value', '');
  $('#search-term').html('');
  $('input[type="checkbox"]:checked').prop('checked', false);
  $('#refresh').toggleClass('ready');
  $('#refresh').prop('disabled', false);
  window.location.href = '/#';
});

$('.check').click(function (e) {
  $('#refresh').addClass('ready');
  $('#refresh').prop('disabled', false);
});

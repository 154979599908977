var analytics = require('./analytics.js');

var gallery = {
	init: function () {
		$('.gallery').each(function () {
			$(this).on('init', function () {
				$(this).find('.slick-dots button').click(function () {
					analytics.track('Engagement', 'gallery-dots', 'Gallery', 40, false);
				});
			}).on('beforeChange', function () {
				analytics.track('Engagement', 'gallery-nav', 'Gallery', 40, false);
			}).slick({
				dots: true,
				infinite: true,
				slide: '.gallery-item',
				slidesToShow: 1,
				speed: 500,
				lazyLoad: 'ondemand',
			});
		});
	},
};

module.exports = {
	init: gallery.init,
};
/*jshint browser: true*/

var analytics = require('./analytics.js');

var menu = {
	$menus: $('.mega-menu'),
	$links: $('.mega-menu-link'),
	$subLinks: $('.mega-menu-sub-link'),
	$menuToggle: $('.menu-toggle'),
	touchSupport: 'ontouchstart' in window || navigator.maxTouchPoints,
	transitionSpeed: 500,
	transitionFunction: 'linear',

	init: function () {
		menu.addAnalyticsEvents();

		var eventTrigger = menu.touchSupport ? 'click' : 'mouseenter';

		// Set preview as first sub-link for each Mega Menu
		menu.$menus.each(function () {
			var $initial = $(this).find('a').eq(0);
			menu.addPreviewMarkup($initial);
			menu.setPreview($initial);
		});

		// Mega Menu hover/touch events
		$(document).on(eventTrigger, menu.$links.selector, function (e) {
			e.preventDefault();

			var $targetMenu = $(this).siblings('.mega-menu');

			if ($targetMenu.parent('li').siblings().children('.mega-menu.-active, .mega-menu.-focused').length) { // Check if other Mega Menu is open
				menu.$menus.filter('.-active, .-focused').removeClass('-active -focused').hide();

				$targetMenu.addClass('-active').show();
			} else if ($targetMenu.hasClass('-active') && menu.touchSupport) { // Check if this Mega Menu is open
				$targetMenu.removeClass('-active').fadeOut(menu.transitionSpeed, menu.transitionFunction);
			} else { // No Mega Menu currently open
				$targetMenu.addClass('-active').fadeIn(menu.transitionSpeed, menu.transitionFunction);
			}
		});

		if (!menu.touchSupport) {
			$(document).on('mouseenter', '.header-site-links .link:not(.mega-menu-link)', function () {
				menu.$menus.filter('.-active').removeClass('-active').hide();
			});

			$(document).on('mouseleave', '.header-site', function () {
				menu.$menus.filter('.-active').removeClass('-active').fadeOut(menu.transitionSpeed, menu.transitionFunction);
			});
		}

		// Show mega-menu when mega-menu link is focused
		$(document).on('focus', menu.$links.selector, function () {
			menu.$links.siblings('.mega-menu').removeClass('-focused');

			$(this).siblings('.mega-menu').addClass('-focused');
		});

		// Hide mega-menu when Prep link is focused
		$(document).on('focus', '.header-site-links li:last-child a', function () {
			menu.$links.siblings('.mega-menu').removeClass('-focused');
		});

		// Make sure mega-menu stays visible once tabbing to mega-menu links
		$(document).on('focus', menu.$subLinks.selector, function () {
			$(this).parents('.mega-menu').addClass('-focused');
		});

		// Hide mega-menu when reverse tabbing through nav items
		$(document).on('focus', '.search input', function () {
			$(this).parents('.container').children('nav').find('.-focused').removeClass('-focused');
		});

		// Sub-links
		$(document).on('mouseenter', menu.$subLinks.selector, function () {
			menu.setPreview($(this));
		});

		// Menu toggle
		$(document).on('click', menu.$menuToggle.selector, function () {
			$('body').toggleClass('-no-scroll');
			$(this).toggleClass('-active');
			$(this).siblings('.header-site').find('nav, .search').fadeToggle(menu.transitionSpeed);
			return false;
		});

		$(window).on('resize', function () {
			if ($(window).width() > 1024) {
				$('header').find('nav, .search').show();
			}
		});
	},

	addPreviewMarkup: function ($elem) {
		var $preview = $elem.parents('.inner').children('.preview');

		$preview.append(
			$('<h3/>').addClass('title'),
			$('<div/>').addClass('image').append(
				$('<img/>').attr({
					src: '',
					alt: '',
				})
			),
			$('<div/>').addClass('description')
		);
	},

	setPreview: function ($targetSubLink) {
		var $preview = $targetSubLink.parents('.inner').children('.preview');
		var title = $targetSubLink.html();
		var imgSrc = $targetSubLink.data('img');
		var description = $targetSubLink.data('description');

		$preview.children('.title').html(title);
		$preview.find('.image img').attr('src', imgSrc);
		$preview.find('.image img').attr('alt', title);
		$preview.children('.description').html(description);
	},

	addAnalyticsEvents: function () {
		$('#primary-header .visitmain').click(function () {
			analytics.track('Engagement', 'header-npws', 'Click header - go to full NPWS website', 40, true);
		});

		$('#primary-header .social-links .facebook a').click(function () {
			analytics.track('Engagement', 'header-facebook', 'Click header - Facebook', 100, true);
		});

		$('#primary-header .social-links .instagram a').click(function () {
			analytics.track('Engagement', 'header-instagram', 'Click header - Instagram', 100, true);
		});

		$('#primary-header .header-site-links .link').click(function () {
			var title = $(this).text().toLowerCase();
			analytics.track('Engagement', 'nav-' + title, 'Nav bar', 20, true);
		});

		$('#primary-header .mega-menu-sub-link').click(function () {
			var title = $(this).text().toLowerCase().replace(/ /g, '');
			var category = $(this).parents('.mega-menu').siblings('.mega-menu-link').text();
			analytics.track('Navigation', 'subnav-' + title, 'Subnav - ' + category, 40, true);
		});
	},
};

module.exports = {
	init: menu.init,
};

// TODO: update the startIndex and endIndex 
module.exports = {
	init: function() {
        var searchItemsContainer = document.querySelector('ul.search-items');
        var searchItemsLi = searchItemsContainer.getElementsByTagName('li');
        var loadMoreContainer = $('.loadmore-search-container');
        var tilesPerLoad = 6;
        var startIndex = 0;

        // Check if tiles are less than 6
        if (searchItemsLi.length < 6) {
            loadMoreContainer.style.display = 'none';
            return;
        }

        // Hide all tiles initially
        for (let i = 6; i < searchItemsLi.length; i++) {
            const tile = searchItemsLi[i];
            tile.style.display = 'none';
        }

        // Update the start index
        startIndex += tilesPerLoad;

        loadMoreContainer.on('click', function () {

            // Show the next set of tiles
            for (let tileIndex = startIndex; tileIndex < startIndex + tilesPerLoad; tileIndex++) {
                if (tileIndex < searchItemsLi.length) {
                    const tile = searchItemsLi[tileIndex];
                    tile.style.display = 'flex';
                }
            }

            // Update the start index
            startIndex += tilesPerLoad;
            
            // Hide the Load More button if all tiles are displayed
            if (startIndex >= searchItemsLi.length) {
                loadMoreContainer.hide();
            }
        });

    }
};


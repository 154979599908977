/*jshint browser: true*/

var analytics = require('./analytics.js');

var slider = {
	medium: 1190,
	single: {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		speed: 500,
		zIndex: 500,
		lazyLoad: 'ondemand',
	},
	triple: {
		dots: true,
		infinite: true,
		slidesToScroll: 3,
		slidesToShow: 3,
		speed: 500,
		responsive: [
			{
				breakpoint: 667,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
		],
		zIndex: 500,
	},
	$slider: $('.slider'),
	resizeTimer: '',

	init: function () {
		$(window).on('resize', function () {
			clearTimeout(slider.resizeTimer);
			slider.resizeTimer = setTimeout(function () {
				slider.$slider.each(function () {
					slider.setHeights($(this));
				});
			}, 250);
		});

		slider.$slider.on('init', function () {
			slider.setHeights($(this));

			var type = $(this).data('type');
			var title = $(this).data('title');
			if (type && title) {
				$(this).find('.slick-dots button').click(function () {
					var value = 40;
					if (type === 'featured') {
						value = 50;
					}

					analytics.track('Engagement', type + '-dots', title, value, false);
				});
			}
		});

		slider.$slider.each(function () {
			if ($(this).hasClass('-feature')) {
				$(this).slick(slider.single).on('beforeChange', function () {
					var type = $(this).data('type');
					var title = $(this).data('title');

					if (type && title) {
						var value = 40;
						if (type === 'featured' || type === 'something-else') {
							value = 80;
						}

						analytics.track('Engagement', type + '-nav', title, value, false);
					}
				});
			} else if ($(this).hasClass('-triple')) {
				$(this).slick(slider.triple).on('beforeChange', function () {
					var type = $(this).data('type');
					var title = $(this).data('title');

					if (type && title) {
						var value = 40;
						if (type === 'featured' || type === 'something-else') {
							value = 80;
						}

						analytics.track('Engagement', type + '-nav', title, value, false);
					}
				});
			}
		});
	},

	setHeights: function ($that) {
		var infoHeight = 0;

		$that.find('.tile').each(function () {
			$(this).find('.info').height('auto');

			if ($(this).find('.info').height() > infoHeight) {
				infoHeight = $(this).find('.info').height();
			}
		});

		$that.find('.tile').each(function () {
			$(this).find('.info').height(infoHeight);
		});
	},
};

module.exports = {
	init: slider.init,
};
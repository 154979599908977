var heroCaption = {
	$caption: $('.hero figcaption'),

	init: function () {
		heroCaption.$caption.on('mouseenter', function () {
			$(this).addClass('-active');
		}).on('mouseleave', function () {
			$(this).removeClass('-active');
		}).on('touchstart', function () {
			$(this).toggleClass('-active');
			$(this).parent().toggleClass('-caption-active');
		});
	},
};

module.exports = {
	init: heroCaption.init,
};

var analytics = require('./analytics.js');

var category = {
	init: function () {
		$('.slider.-feature .tile a').click(function () {
			analytics.track('Engagement', 'featured-article', 'Featured module', 50, false);
		});

		$('.something-else .slider a').click(function () {
			analytics.track('Engagement', 'something-else-click', 'Something else', 80, false);
		});
	},
};

module.exports = {
	init: category.init,
};

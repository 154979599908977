var analytics = {
	init: function () {
		// Init scrolldepth
		$.scrollDepth({
			pixelDepth: false,
			eventHandler: function (data) {
				try {
					var trackers = ga.getAll();

					if (data.event === 'ScrollTiming') {
						$.each(trackers, function () {
							ga(this.get('name') + '.send', 'timing', data.eventCategory, data.eventAction, data.eventTiming, data.eventLabel);
						});
					} else if (data.event === 'ScrollDistance') {
						var value = 20;
						data.eventCategory = 'Engagement';
						if (data.eventLabel === '50%') {
							value = 40;
						} else if (data.eventLabel === '75%') {
							value = 60;
						} else if (data.eventLabel === '100%') {
							value = 80;
							data.eventCategory = 'Conversion';
						}

						$.each(trackers, function () {
							ga(this.get('name') + '.send', 'event', data.eventCategory, data.eventAction, data.eventLabel, value, {
								nonInteraction: true,
							});
						});
					}
				} catch (error) {
					return false;
				}
			},
		});

		// Init outbound NPWS link tracking
		$('a[href^="http://www.nationalparks.nsw.gov.au"], a[href^="https://www.nationalparks.nsw.gov.au"]').click(function () {
			analytics.track('Conversion', 'npws-link', $(this).attr('href'), 100, false);
		});
	},

	track: function (category, action, label, value, nonInteraction) {
		try {
			$.each(ga.getAll(), function () {
				ga(this.get('name') + '.send', 'event', category, action, label, value, {
					nonInteraction: nonInteraction,
				});
			});
		}

		catch (error) {
			console.log('[EXCEPTION] Analytics blocked by user-agent (' + error.message + ')');

			return false;
		}
	},
};

module.exports = {
	init: analytics.init,
	track: analytics.track,
};

//------------------------------------------------------//
//--------BEGIN ANIMATING PLACEHOLDER TEXT--------------//
//------------------------------------------------------//

function surveyTypeWriter(text, ID) {
	var txt = text;
	var timeOut;
	var txtLen = txt.length;
	var char = 0;
	var fieldID = ID;
	(function typeIt() {
		var humanize = Math.round(Math.random() * (300 - 30)) + 30;
		timeOut = setTimeout(function () {
			char++;
			var type = txt.substring(0, char);
			$(fieldID).text(type + '|');
			typeIt();

			if (char === txtLen) {
				$(fieldID).text(
					$(fieldID)
						.text()
						.slice(0, -1)
				); // remove the '|'
				clearTimeout(timeOut);
			}
		}, humanize);
	})();
}

$(document).on('scroll', function () {
	var topOfElement = $('.signup').offset().top;
	var bottomOfScreen = $(window).scrollTop() + $(window).height();
	if (bottomOfScreen > topOfElement) {
		$(document).off('scroll');

		setTimeout(function () {
			surveyTypeWriter('national park', 'a.nationalPark');
		}, 500);

		setTimeout(function () {
			surveyTypeWriter('activities', 'a.activities');
		}, 2000);

		setTimeout(function () {
			surveyTypeWriter('my', 'a.my');
		}, 4000);

		setTimeout(function () {
			surveyTypeWriter('stars', 'a.stars');
		}, 5000);
	}
});

//------------------------------------------------------//
//--------END ANIMATING PLACEHOLDER TEXT----------------//
//------------------------------------------------------//

function resizeInput() {
	var $elem = $('input.nationalPark');
	if ($elem.val().length < 1) {
		$elem.attr('size', 1);
	} else if ($elem.attr('mw') > 0) {
		var maxWidth = $elem.attr('mw');
		if ($elem.val().length < maxWidth) {
			$elem.attr('size', $elem.val().length);
		} else {
			$elem.attr('size', maxWidth);
		}
	} else {
		$elem.attr('size', $elem.val().length);
	}
}

$(document).ready(function () {
	if (document.location.search === '?survey=complete') {
		$('#form188').hide();
		$('.thanks').fadeIn(500);
	}

	$('form span > a').click(function (event) {
		event.preventDefault();
		return false;
	});

	//count checkboxes selected and append number to label
	function updateCounter(divID, label, text) {
		var len = $(divID + ' input[type=checkbox]:checked').length;
		if (len > 0) {
			if (len === 1) {
				$(label).text(len + ' activity');
			} else {
				$(label).text(len + ' activities');
			}
		} else {
			$(label).text(text);
		}
	}

	//make all labels the same width in each group
	function reWidth(label) {
		var widestBox = 0;
		$(label).each(function () {
			if ($(this).width() > widestBox) {
				widestBox = $(this).width();
			}
		});

		$(label).width(widestBox + 5);
	}

	//position popup in absolute center
	function reMargin(div) {
		var height = $(div).height();
		var width = $(div).width();

		//for absolute center, marginleft and margintop
		//must be negative of half
		var marginTop = -(height / 2);
		var marginLeft = -(width / 2);
		$(div).css({
			marginTop: marginTop,
			marginLeft: marginLeft,
		});
	}

	$('input.nationalPark')
		.keyup(resizeInput)
		.each(resizeInput);

	$('#comments').keyup(function () {
		$(this).css('height', '5px');
		$(this).css('height', $(this).prop('scrollHeight') + 'px');
	});

	reMargin('div.region');
	reMargin('div.park');
	reMargin('div.companion');
	reMargin('div.stars');
	if ($(window).width() > 700) {
		reMargin('div.activities');
	}

	$('div.activities span').click(function () {
		$(this)
			.prev()
			.trigger('click');
	});

	$(window).resize(function () {
		if ($(window).width() > 700) {
			reMargin('div.activities');
		}
	});

	//when user selects a park, change the 'a' text
	$('input[type=radio][name=park]').change(function () {
		var value = $(this).val();
		$('a.nationalPark').text(value);
	});

	//when user selects 'my'/companion, change the 'a' text
	$('input[type=radio][name=my]').change(function () {
		var value = $(this).val();
		$('a.my').text(value);
	});

	//when user selects 'star rating', change the 'a' text
	$('input[type=radio][name=star]').change(function () {
		var value = $(this).val();
		$('a.stars').text(value + ' stars');
	});

	//show popups
	$('a.region').click(function () {
		$('div.park, div.companion, div.stars').hide();
		$('div.region').fadeIn();
		$('div.region input:first').focus();
		reWidth('.region label');
		$('.overlay').fadeIn();
	});

	$('a.activities').click(function () {
		$('div.region, div.park, div.stars, div.my').hide();
		$('div.activities').fadeIn();
		$.scrollLock(true);
		$('div.activities input:first').focus();
		$('.overlay').fadeIn();
	});

	$('a.my').click(function () {
		$('div.region, div.park, div.stars').hide();
		$('div.companion').fadeIn();
		$('div.companion input:first').focus();
		reWidth('.companion label');
		$('.overlay').fadeIn();
	});

	$('a.stars').click(function () {
		$('div.region, div.companion, div.stars').hide();
		$('div.stars').fadeIn();
		$('div.stars input:first').focus();
		reMargin($('div.stars'));
		reWidth('.stars label');
		$('.overlay').fadeIn();
	});

	//hide popups
	$('.region label, .companion label, .stars label').click(function () {
		$(this)
			.parent()
			.fadeOut();
		$('.overlay').fadeOut();
	});

	//hide popup for parks
	$('.park label').click(function () {
		$('.park').fadeOut();
		$('.overlay').fadeOut();
	});

	$('.close').click(function () {
		$.scrollLock(false);
		$('div.activities, .overlay').fadeOut();
		updateCounter('.activities', 'a.activities', 'activities');
	});

	$('textarea').focus(function () {
		$('div.region, div.companion, div.stars', 'div.activities', 'div.park').hide();
	});

	$('input.nationalPark').click(function () {
		$(this).val('');
	});

	//----------------------------------------------------------------//
	//-------------------Start Custom Validation ---------------------//
	//----------------------------------------------------------------//
	var parks;
	var data = {};
	var $form = $('#form188');

	function ajaxRequest() {
		var $activitiesInput = $('form .activities input:checked').serializeArray();
		var activities = [];

		$activitiesInput.forEach(function (activity) {
			activities.push(activity.value);
		});

		data.name = $('form .name-input').val();
		data.email = $('form .email-input').val();
		data.parks = $.trim($('input.nationalPark').val());
		data.my = $('form .companion input:checked').val();
		data.star = $('form .stars input:checked').val();
		data.activity = activities;
		data.comments = $('form textarea').val();

		$.ajax({
			type: $form.attr('method'),
			url: $form.attr('action'),
			data: data,
			success: function () {
				$form.replaceWith($('<p/>').text('Thanks for completing the survey.'));
			},

			error: function () {
				$form.replaceWith($('<p/>').html('There was a problem processing your survey. Please try again later or email us at <a href="mailto:parks.blogs@environment.nsw.gov.au">parks.blog@environment.nsw.gov.au</a>.'));
			},
		});
	}

	$form.submit(function (e) {
		//event.preventDefault();
		e.preventDefault();
		var nationalParkInput = $.trim($('input.nationalPark').val());
		var radioMy = $("input:radio[name='my']").is(':checked');
		var radioStars = $("input:radio[name='star']").is(':checked');
		var activitiesSelected = $('input[name="activity[]"]:checked').length;

		if (nationalParkInput === '' || nationalParkInput === 'park' || parks.indexOf(nationalParkInput) === -1) {
			alert('Please select a national park.');
			return false;
		} else if (activitiesSelected < 1) {
			alert('Please let us know what activities you did.');
			return false;
		} else if (!radioMy) {
			alert('Please let us know who you went with.');
			return false;
		} else if (!radioStars) {
			alert('Please give your experience a rating.');
			return false;
		} else {
			ajaxRequest();
		}
	});

	//----------------------------------------------------------------//
	//---------------------End Custom Validation ---------------------//
	//----------------------------------------------------------------//

	// Set up autocomplete on parks field
	$.ajax({
		url: $('meta[name=base_url]').attr('content') + '/api/parks',
		dataType: 'json',
		success: function (data) {
			parks = data;
			$(function () {
				parks = parks.sort();
				$('input.nationalPark').autocomplete({
					source: parks,
					minLength: 2,
					close: function () {
						var text = $('input.nationalPark').val();
						var span = $('.hiddenSpan').text(text);
						var length = span.width();
						$(this).width(length);
					},
				});
			});
		},
	});
});


module.exports = {
	init: function () {
        $('#welcome .tab a').on('click', function (e) {
  
            e.preventDefault();
            
            $(this).parent().addClass('active');
            $(this).parent().siblings().removeClass('active');
            
            target = $(this).attr('href');
          
            $('.tab-content > div').not(target).hide();
            
            $(target).fadeIn(1000);
            
          });
	},
};

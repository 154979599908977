var notification = {

	init: function () {
		notification.$elem = $('.notification');
		notification.cookieKey = 'yac-notificiation-dismissed';

		// Check if user has already dismissed the notice OR if we're on the allergen page already
		if(Cookies.get(notification.cookieKey) || notification.isNotificationPage()) {
			notification.$elem.remove();
		} else {
			setTimeout(function () {
				$('.notification').addClass('show-notification');
			}, 4000);

			notification.$elem.find('.modal-close').click(function() {
				notification.dismiss();
				return false;
			});
		}
	},

	dismiss: function() {
		Cookies.set(notification.cookieKey,true);
		notification.$elem.remove();
	},

	isNotificationPage: function() {
		var buttonHref = notification.$elem.find('.-button').attr("href");
		return window.location.href.indexOf(buttonHref) > -1;
	}
};

module.exports = {
	init: notification.init,
};

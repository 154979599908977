var analytics = require('./analytics.js');

var post = {
	$storage: $.localStorage,
	storageKey: 'visited',
	$current: $('article.article'),
	$footer: $('.article-footer'),
	finished: false,

	init: function () {
		post.addAnalyticsEvents();
		post.trackRead();
		post.lazyGiphy();
	},

	// Lazy load giphy gifs
	lazyGiphy: function () {
		post.$current.find('.giphy.-lazy').unveil(200, function () {
			$(this).append($('<iframe/>').attr({
				class: 'giphy-embed',
				src: $(this).data('src'),
				frameborder: 0,
				allowfullscreen: 'allowfullscreen',
			}));
		});
	},

	// Save post ID to local storage
	trackRead: function () {
		// Ensure local storage is supported
		if (post.checkStorageSupport()) {
			var visited = post.$storage.get(post.storageKey);
			var current = post.$current.data('id');

			if (!visited) {
				// First visited article. Create array
				visited = [current];
			} else {
				var last = visited[0];
				if (last !== current) {
					// User has visited a new article. Add it to the list
					visited.unshift(current);

					if (visited.length > 5) {
						// Let's only keep track of 5 articles
						visited.pop();
					}
				} else {
					// The user is reading the same article that they read last visit
					return;
				}
			}

			post.$storage.set(post.storageKey, visited);
		}
	},

	checkStorageSupport: function () {
		var testKey = 'test';
		var storage = window.sessionStorage;

		try {
			storage.setItem(testKey, '1');
			storage.removeItem(testKey);
			return true;
		}

		catch (error) {
			return false;
		}
	},

	addAnalyticsEvents: function () {
		$('#breadcrumbs a').click(function () {
			var index = $(this).parent().index() + 1;
			var cat = !index ? 'category' : 'subcategory';
			analytics.track('Navigation', 'breadcrumb-' + index, 'Breadcrumb ' + cat, 0, false);
		});

		$('.something-else .tile a').click(function () {
			analytics.track('Engagement', 'something-else-click', 'Click something else', 80, false);
		});

		$('.suggested-articles .suggested-article a').click(function () {
			analytics.track('Engagement', 'recommended-story', 'Recommended story', 40, false);
		});

		$('.prev-next .tile a').click(function () {
			if ($(this).parents('li').hasClass('-prev')) {
				analytics.track('Engagement', 'previous-story', 'Previous story', 20, false);
			} else {
				analytics.track('Engagement', 'next-story', 'Next story', 80, false);
			}
		});

		// track when user reaches bottom of article
		$(window).on('scroll', function () {
			if (!post.finished) {
				var footer = post.$footer.offset().top;
				var current = $(window).scrollTop() + $(window).height();
				if (current > footer) {
					post.finished = true;
					analytics.track('Engagement', 'article-completed', post.$current.data('slug'), 80, true);
				}
			}
		});

		// Check if this article is tagged 'conservation'
		var conservation = false;
		$('.article-footer .tags a').each(function () {
			if ($(this).text().toLowerCase() === 'conservation') {
				conservation = true;
				return false;
			}
		});

		// Fire an event after 6 seconds if the article is tagged 'conservation'
		if (conservation) {
			setTimeout(function () {
				analytics.track('Conversion', 'conservation', post.$current.data('slug'), 80, false);
			}, 6000);
		}
	},
};

module.exports = {
	init: post.init,
};

// GA
var analytics = require('./analytics.js');

var customMap = {

	init: function () {
		$('.custom-map').click(function () {
			$('.custom-map .responsive-embed iframe').css('pointer-events', 'auto');
			if (!$(this).data('interacted')) {
				analytics.track('Intent', 'custom-map-click', 'Custom Map Interaction', 100, false);
			}

			$(this).data('interacted', true);
		});

		$('.custom-map').mouseleave(function () {
			$('.custom-map .responsive-embed iframe').css('pointer-events', 'none');
		});

		$('.map-pdf-link').click(function () {
			analytics.track('Intent', 'custom-map-pdf', 'Custom Map - Open PDF Version', 100, false);
		});
	},
};

module.exports = {
	init: customMap.init,
};
